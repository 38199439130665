import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html'
})
export class DialogComponent implements OnInit {

    @Input()
    dialogTitle: string;

    @Input()
    dialogTitleIcon: string;

    @Input()
    dialogSubTitle: string;

    @Input()
    showFooterActions: boolean = true;

    @Input()
    showClose: boolean = false;

    @Input()
    handleCloseEvent: boolean = false;

    @Input()
    heading: string;

    @Input()
    caption: string;

    @Input()
    description: string;

    @Input()
    defaultWidth: string = 'auto';

    @Input()
    defaultHeight: string = 'auto';

    @Input()
    centerAlign: boolean = false;

    @Input()
    position: string;

    @Input()
    colorClass: string = null;

    @Input()
    noBodyPadding: boolean = false;

    @Input()
    noSubHeaderPadding: boolean = false;

    @Output()
    Close: EventEmitter<void> = new EventEmitter();

    @Output()
    contentScroll = new EventEmitter<Event>();

    private heightToUse: string;
    private widthToUse: string;

    screenHeight: string;
    screenWidth: string;

    constructor(private dialogRef: MatDialogRef<any>) {
        setTimeout(() => this.onResize(), 1);
    }

    ngOnInit(): void {
        setTimeout(() => this.onResize(), 1);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.screenHeight = `${window.innerHeight}px`;
        this.screenWidth = `${window.innerWidth}px`;

        if (this.defaultWidth.indexOf('px') && this.defaultHeight.indexOf('px')) {
            if (window.innerHeight <= parseInt(this.defaultHeight, 10)) {
                this.heightToUse = this.screenHeight;
            } else {
                this.heightToUse = this.defaultHeight;
            }

            if (window.innerWidth <= parseInt(this.defaultWidth, 10)) {
                this.widthToUse = this.screenWidth;
            } else {
                this.widthToUse = this.defaultWidth;
            }
        } else {
            this.heightToUse = this.defaultHeight;
            this.widthToUse = this.defaultWidth;
        }

        this.dialogRef.updateSize(this.widthToUse, this.heightToUse);
    }

    onCloseClick(): void {
        if (this.handleCloseEvent) {
            this.Close.emit();
        } else {
            this.dialogRef.close();
        }
    }

    onContentScroll(event: Event): void {
        this.contentScroll.emit(event);
    }
}