import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// rxjs
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-image',
    template: `
        <img [className]="className ?? ''" [src]="dataUrl$|async" [style]="!className ? 'width: 100%; height: 100%' : null" (click)="onClicked($event)"/>
    `
  })
  export class SecureImageComponent implements OnChanges  {
    @Input()
    src: string;

    @Input()
    className: string;

    @Output()
    imageClick: EventEmitter<any> = new EventEmitter();

    private src$ = new BehaviorSubject(null);
    dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));

    constructor(
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer) {
    }

    ngOnChanges(): void {
      this.src$.next(this.src);
    }

    onClicked($event: any): void {
      this.imageClick.emit($event);
    }

    private loadImage(url: string): Observable<any> {
        return this.httpClient
            .get(url, {responseType: 'blob'})
            .pipe(map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
    }
}