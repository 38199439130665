<div class="postcode-observation" fxLayout="column">
    <div>
        <mat-form-field appearance="outline" class="observation-field" *ngIf="!showAddress">
            <input matInput id="observation" name="observation" class="postcode"
                placeholder="Search using a postcode"
                (focus)="setSelected()"
                (keydown)="settingPostcode()"
                autocomplete="off"
                #postcode
                pattern="^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$"
                [readonly]="item.readonly"/>
            <mat-error *ngIf="form.invalid && form.dirty && form.touched">Postcode does not match required UK format</mat-error>
        </mat-form-field>
    </div>

    <mat-error *ngIf="lookupError.length > 0">
        {{lookupError}}
    </mat-error>
    <mat-form-field appearance="outline" class="observation-field" *ngIf="hasAddresses">
        <mat-select #address placeholder="Addresses" (selectionChange)="updateAddress(address.value)" >
            <mat-option *ngFor="let address of addresses" [value]="address">{{formatAddress(address)}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="showAddress" name="addressForm" [formGroup]="addressForm" novalidate fxLayout="column">
        <div>
            <div class="font-question-label">Postcode *</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Postcode *" formControlName="postcode">
            </mat-form-field>
        </div>
        <div>
            <div class="font-question-label">First Line *</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="First line of the address *" formControlName="addressLine1">
            </mat-form-field>
        </div>
        <div>
            <div class="font-question-label">Second Line</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Second line of the address" formControlName="addressLine2">
            </mat-form-field>
        </div>
        <div>
            <div class="font-question-label">Town *</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Town *" formControlName="town">
            </mat-form-field>
        </div>
        <div>
            <div class="font-question-label">County</div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="County" formControlName="county">
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="!showAddress" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="15px">
        <button  class="btn large primary dark" (click)="lookupAddress()">Search</button>
        <button  class="btn large primary dark" (click)="toggleDataEntry(true);">Enter Manually</button>
    </div>
    <div *ngIf="showAddress" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="15px">
        <button  class="btn large primary dark" (click)="toggleDataEntry(false)">Search Again</button>
        <button  class="btn large primary dark" (click)="clearAddress()">Clear Address</button>
    </div> 
</div>