<div class="idv-document-rejected-step" fxLayout="column"fxLayoutGap="32px">
    <div fxLayout="column" fxLayoutGap="16px">
        <div class="text headline align-center">Document rejected</div>
        <div class="text sub-title align-center">We couldn't recognise your ID document. Please try again following the steps below.</div>
    </div>
    <div fxLayout="column" fxLayoutGap="32px">
        <div fxLayout="row" fxLayoutGap="32px" fxLayout="start top">
            <div fxFlex><img src="assets/idv/WellLitExample.png"></div>
            <div fxFlex><img src="assets/idv/LineUpExample.png"></div>
        </div>
        <div fxLayout="row" fxLayoutGap="32px" fxLayout="start top">
            <div fxFlex><img src="assets/idv/InFocusExample.png"></div>
            <div fxFlex><img src="assets/idv/NoGlareExample.png"></div>
        </div>
        <div fxLayout="column" fxLayoutGap="16px">
            <button class="btn large primary dark" (click)="handleRetakePhotoClick()">Retake</button>
        </div>
    </div>
</div>