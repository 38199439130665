import { Component, Input } from '@angular/core';

// enums
import { LozengeVariant as LozengeVariant } from 'app/connect/enums/lozenge-variant.enum';

@Component({
    selector: 'app-lozenge',
    templateUrl: 'lozenge.component.html',
    styleUrls: ['lozenge.component.scss'],
})
export class LozengeComponent {
    @Input()
    text: string;

    @Input()
    variant: LozengeVariant;

    /**
     * Overrides the variant and applies
     * the style to the class attribute
     */
    @Input()
    style: string;

    getVariantStyle() {
        switch (this.variant) {
            case LozengeVariant.Amber:
                return 'rag-amber-bg text-gray-8';
            case LozengeVariant.Green:
                return 'rag-green-bg text-white';
            case LozengeVariant.LightBlue:
                return 'background-lightblue text-gray-8';
            case LozengeVariant.Primary:
                return 'primary-bg primary-reverse-text';
            case LozengeVariant.Red:
                return 'rag-red-bg text-white';
            case LozengeVariant.Black:
                return 'background-gray-8 text-white';
            case LozengeVariant.Gray:
                return 'background-gray-2 text-gray-8';
        }
    }
}
