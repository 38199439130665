<div class="profile-status theme-background-8">
    <div class="inner-container">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" id="inner-div">
            <div id="img_container" fxFlex.gt-xs="50%"><img *ngIf="icon" [src]="icon | safe: 'resourceUrl'" /></div>
            <div fxFlex.gt-xs="50%">
                <div fxLayout="column" fxFlex>
                    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="start start">
                        <div fxFlex class="label">{{label}} is <strong>{{profileCompletePercentage | number: '1.0-2'}}% complete </strong></div>
                        <div class="button">
                            <button
                            *ngIf="isEditable"
                            class="btn large primary dark"
                            (click)="handleCompleteNowClicked()">Complete Now</button>
                        </div>
                    </div>

                    <div class="status-bar">
                        <div class="status-bar-complete" [ngStyle]="{ 'width': profileCompletePercentage + '%' }"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>