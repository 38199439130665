<div class="idv-id-document-step" fxLayout="column"fxLayoutGap="32px">
    <div fxLayout="column" fxLayoutGap="32px" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start top">
        <div class="text align-center">
            <img src="assets/idv/CredasSampleIdDocument.png"/>
        </div>
        <div fxLayout="column" fxLayoutGap="16px">
            <div class="text headline">ID Document</div>
            <div class="text sub-title">Take a photo of a government issued document you would like to use.</div>
            <div>
                <div class="font-question-label">ID Document Type</div>
                <mat-form-field appearance="outline">
                    <mat-select (selectionChange)="handleIdDocumentTypeSelected(idDocumentTypeSelect.value)" #idDocumentTypeSelect>
                        <mat-option *ngFor="let idDocument of idDocuments" [value]="idDocument.value">{{idDocument.title}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>        
    </div>        
    <div fxLayout="column" fxLayoutGap="16px">
        <button *ngIf="(isMobileDevice$ | async) === false" class="btn large primary dark"  [disabled]="!selectedDocumentType" (click)="handleTakePhotoClick()">Take Photo</button>
        <button *ngIf="(isMobileDevice$ | async) === false" class="btn large primary dark"  [disabled]="!selectedDocumentType" (click)="upload.click()">Upload</button>
        <button *ngIf="allowSwitchToMobile && (isMobileDevice$ | async) === false" class="btn large primary dark"  (click)="handleSwitchToMobileClick()">Switch to mobile</button>
        <button *ngIf="isMobileDevice$ | async" class="btn large primary dark"  [disabled]="!selectedDocumentType" (click)="mobileCapture.click()">Take Photo</button>
    </div>

    <input type="file" #upload [hidden]="true" (change)="handleFileUploaded($event)">
    <input type="file" #mobileCapture [hidden]="true" (change)="handleFileUploaded($event)" capture="user" accept="image/*">
</div>