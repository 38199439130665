<div fxFlex="100" id="policy-popup">
    <h3 mat-dialog-title>Privacy Policy</h3>
    <mat-dialog-content>
        <div [innerHtml]="policyText | safe: 'html'"> </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button class="btn large primary dark" mat-dialog-close>OK</button>
    </mat-dialog-actions>
</div>
