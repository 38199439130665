// Store
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'app/authentication-v2/store/actions';

// Models
import { TemporaryPasswordReset } from 'app/authentication-v2/models/temporary-password-reset.model';


export interface AuthenticationState {
    working: boolean;
    authenticationMessage: string;
    passwordResetOnLogin: TemporaryPasswordReset;
    acceptingTerms: boolean;
}

const initialState: AuthenticationState = {
    working: null,
    authenticationMessage: null,
    passwordResetOnLogin: null,
    acceptingTerms: false
};

const authReducer = createReducer(
    initialState,
    on(actions.Login, (state) => ({
        ...state,
        working: true,
        authenticationMessage: null
    })),
    on(actions.LoginFail, (state, action) => ({
        ...state,
        working: false,
        authenticationMessage: action.message
    })),
    on(actions.LoginSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.RehydrateUserSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.Logout, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.PasswordResetRequired, (state, action) => ({
        ...state,
        passwordResetOnLogin: action.request,
        working: false,
    })),
    on(actions.ClearPasswordResetState, (state) => ({
        ...state,
        passwordResetOnLogin: null,
        working: false
    })),
    on(actions.TermsAccepted, (state) => ({
        ...state,
        acceptingTerms: true
    })),
    on(actions.TermsAcceptedSuccess, (state) => ({
        ...state,
        acceptingTerms: false
    })),
    on(actions.TermsAcceptedFail, (state) => ({
        ...state,
        acceptingTerms: false
    }))
);

export function reducer(state: AuthenticationState, action: Action) {
    return authReducer(state, action);
}