import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';

// ngrx | rxjs
import { ActionsSubject, Store } from '@ngrx/store';
import { first, Observable, takeUntil } from 'rxjs';
import { ofType } from '@ngrx/effects';

// store
import * as fromStore from 'app/authentication-v2/store';

// models
import { TermsDetails } from 'app/authentication-v2/models/terms-details.model';
import { LogoutOptions } from 'app/models/logout-options.model';
import { TermsDialogOptions } from 'app/authentication-v2/models/terms-dialog-options.model';
import { User } from 'app/models/user.model';
import { TokenLoginDetails } from 'app/authentication-v2/models/token-login-details.model';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// utilities
import { isNullOrWhitespace } from 'app/shared/utilities/string-utilities';

@Component({
    templateUrl: './terms-dialog.component.html',
    styleUrls: ['./terms-dialog.component.scss'],
})
export class TermsDialogComponent
    extends BaseComponent
    implements OnInit, AfterViewInit {
    terms: TermsDetails = undefined;
    loading$: Observable<boolean>;
    accepting$: Observable<boolean>;
    acceptanceNeeded: boolean;
    user?: User;
    tokenLogin?: TokenLoginDetails;

    form: FormGroup;
    hasScrolledToTheBottom = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: TermsDialogOptions,
        private dialog: MatDialogRef<TermsDialogComponent>,
        private actionsSubject: ActionsSubject,
        private store: Store<fromStore.AuthenticationState>,
        private formBuilder: FormBuilder
    ) {
        super();
        this.acceptanceNeeded = data?.acceptanceRequired;
        this.user = data?.user;
        this.tokenLogin = data?.tokenLogin;
    }

    ngOnInit(): void {
        this.loading$ = this.store.select(fromStore.getLoadingTerms);
        this.accepting$ = this.store.select(fromStore.getAcceptingTerms);
        this.store.dispatch(fromStore.GetCurrentTerms());
        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromStore.TermsAcceptedSuccess)
            )
            .subscribe(() => {
                this.dialog.close(true);
            });

        this.dialog
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (this.acceptanceNeeded && result !== true) {
                    this.store.dispatch(
                        fromStore.Logout({
                            options: new LogoutOptions(false, true, undefined),
                        })
                    );
                }
            });
    }

    ngAfterViewInit(): void {
        this.store
            .select(fromStore.getCurrentTerms)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value) => {
                this.hasScrolledToTheBottom = false;
                this.terms = value;
                this.createForm(value);

                setTimeout(() => this.onTermsScroll(), 100);
            });
    }

    createForm(terms: TermsDetails) {
        this.form = this.formBuilder.group({
            terms: this.formBuilder.array([]),
        });

        const termsArray = this.form.controls.terms as FormArray;
        const checkbox = new FormControl('', Validators.requiredTrue);

        if (terms?.confirmationMessages?.length) {
            terms.confirmationMessages.forEach(() => {
                termsArray.push(checkbox);
            });
        } else {
            termsArray.push(checkbox);
        }
    }

    onTermsScroll(): void {
        const container = document.getElementById('terms-container');
        if (!container || isNullOrWhitespace(this.terms?.content)) {
            return;
        }

        // Allow a threshold of 10 pixels for detecting that all terms have been read.
        // A threshold is necessary as relying solely on the scroll details is sometimes unreliable when dealing with
        // zoomed in browsers.
        const scrollAcceptanceThreshold = 10;

        const reachedTheEnd =
            container.offsetHeight +
                container.scrollTop +
                scrollAcceptanceThreshold >=
            container.scrollHeight;
        if (reachedTheEnd) {
            this.hasScrolledToTheBottom = true;
        }
    }

    onConfirmClick(): void {
        if (!this.hasScrolledToTheBottom && !this.form.invalid) {
            return;
        }

        this.store.dispatch(
            fromStore.TermsAccepted({
                user: this.user,
                tokenLogin: this.tokenLogin,
            })
        );
    }
}
