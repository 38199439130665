import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// modules
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';

// store
import { effects } from 'app/connect/store/effects';
import { reducers } from 'app/connect/store/reducers';

// components
import { BrandingComponent } from './components/branding/branding.component';
import { BrandPanelComponent } from './components/brand-panel/brand-panel.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CardDisplayComponent } from './components/card-display/card-display.component';
import { CardFooterToggleComponent } from './components/card-footer-toggle/card-footer-toggle.component';
import { ClientSelectorComponent } from './components/client-selector/client-selector.component';
import { ClientSelectorOptionComponent } from './components/client-selector-option/client-selector-option.component';
import { FilterComponent } from './components/filter/filter.component';
import { FolderCardComponent } from './components/folder-card/folder-card.component';
import { HeaderComponent } from './components/header/header.component';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { PageComponent } from './components/page/page.component';
import { PoweredByCredasComponent } from './components/powered-by-credas/powered-by-credas.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UserGroupSelectorComponent } from './components/user-group-selector/user-group-selector.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { HelpFaqSearchComponent } from './components/help-faq-search/help-faq-search.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { MaximizerComponent } from 'app/connect/components/maximizer/maximizer.component';
import { LozengeComponent } from './components/lozenge/lozenge.component';

// services
import { ConnectService } from './services/connect.service';
import { HelpFaqService } from './services/help-faq.service';
import { TooltipService } from './services/tooltip.service';

// models
import { StoreNames } from './models/store-names.model';


const modules = [
    CommonModule,
    SharedModule,
    RouterModule,
    EffectsModule.forFeature(effects),
    StoreModule.forFeature(StoreNames.connectCommon, reducers),
];

const services = [
    ConnectService,
    HelpFaqService,
    TooltipService
];

const components = [
    BrandingComponent,
    BrandPanelComponent,
    BreadcrumbComponent,
    CardDisplayComponent,
    CardFooterToggleComponent,
    ClientSelectorComponent,
    ClientSelectorOptionComponent,
    FilterComponent,
    FolderCardComponent,
    HeaderComponent,
    ImagePopupComponent,
    PageComponent,
    PoweredByCredasComponent,
    ToolbarComponent,
    UserGroupSelectorComponent,
    UserMenuComponent,
    HelpFaqSearchComponent,
    TooltipComponent,
    MaximizerComponent,
    LozengeComponent
];

@NgModule({
    imports: modules,
    providers: services,
    declarations: components,
    exports: components
})
export class ConnectModule { }