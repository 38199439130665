// ngrx
import { createReducer, on, Action } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions/user-interface.actions';

// models
import { FilterConfiguration } from 'app/models/filter/filter-configuration.model';
import { CustomMenu } from 'app/models/custom-menu.model';

// enums
import { ViewType } from 'app/connect/enums/view-type.enum';
import { Modules } from 'app/shared/enums';

export interface UserInterfaceState {
    showNewButton: boolean;
    newButtonCaption: string;
    showClientSelector: boolean;
    showUserMenu: boolean;
    showHeader: boolean;
    pageViewType: ViewType;
    pageTitle: string;
    allowExport: boolean;
    allowSwitchView: boolean;
    showShowMoreButton: boolean;
    showMore: boolean;
    filterConfiguration: FilterConfiguration;
    hideNavigation: boolean;
    customMenus: CustomMenu[];
    readOnlyAccess: boolean;
    showUserGroupSelector: boolean;
    showHelpFaqSearch: boolean;
    canEditFoldersPermission: Modules;
    canDeleteFoldersPermission: Modules;
    entitySuperUserView: boolean;
}

const initialState: UserInterfaceState = {
    showNewButton: false,
    newButtonCaption: 'Add new',
    showClientSelector: false,
    showUserMenu: false,
    showHeader: false,
    pageViewType: ViewType.List,
    pageTitle: '',
    allowExport: false,
    allowSwitchView: false,
    showShowMoreButton: false,
    filterConfiguration: undefined,
    showMore: false,
    hideNavigation: false,
    customMenus: null,
    readOnlyAccess: false,
    showUserGroupSelector: false,
    showHelpFaqSearch: false,
    canEditFoldersPermission: undefined,
    canDeleteFoldersPermission: undefined,
    entitySuperUserView: false
};

const applicationReducer = createReducer(
    initialState,
    on(actions.SetShowNewButton, (state, action) => ({
        ...state,
        showNewButton: action.showNewButton,
        newButtonCaption: action.showNewButton ? action.caption : 'Add new'
    })),
    on(actions.OpenClientSelector, (state) => ({
        ...state,
        showClientSelector: true
    })),
    on(actions.CloseClientSelector, (state) => ({
        ...state,
        showClientSelector: false
    })),
    on(actions.OpenUserGroupSelector, (state) => ({
        ...state,
        showUserGroupSelector: true
    })),
    on(actions.CloseUserGroupSelector, (state) => ({
        ...state,
        showUserGroupSelector: false
    })),
    on(actions.OpenUserMenu, (state) => ({
        ...state,
        showUserMenu: true
    })),
    on(actions.CloseUserMenu, (state) => ({
        ...state,
        showUserMenu: false
    })),
    on(actions.ShowHeader, (state) => ({
        ...state,
        showHeader: true
    })),
    on(actions.HideHeader, (state) => ({
        ...state,
        showHeader: false
    })),
    on(actions.ResetPage, (state) => ({
        ...state,
        pageViewType: ViewType.List,
        showNewButton: false,
        pageTitle: '',
        allowExport: false,
        showShowMoreButton: false,
        allowSwitchView: false,
        showMore: false,
        filterConfiguration: undefined,
        hideNavigation: false,
        customMenus: null,
        canEditFoldersPermission: undefined,
        canDeleteFoldersPermission: undefined
    })),
    on(actions.SetPageViewType, (state, action) => ({
        ...state,
        pageViewType: action.viewType
    })),
    on(actions.SetPageTitle, (state, action) => ({
        ...state,
        pageTitle: action.title
    })),
    on(actions.SetAllowExport, (state, action) => ({
        ...state,
        allowExport: action.allowExport
    })),
    on(actions.SetAllowSwitchView, (state, action) => ({
        ...state,
        allowSwitchView: action.allowSwitchView
    })),
    on(actions.SetShowShowMoreButton, (state, action) => ({
        ...state,
        showShowMoreButton: action.showShowMoreButton
    })),
    on(actions.SetShowMore, (state, action) => ({
        ...state,
        showMore: action.showMore
    })),
    on(actions.SetPageFilterConfiguration, (state, action) => ({
        ...state,
        filterConfiguration: action.filterConfiguration
    })),
    on(actions.SetHideNavigation, (state, action) => ({
        ...state,
        hideNavigation: action.hideNavigation
    })),
    on(actions.SetCustomMenus, (state, action) => ({
        ...state,
        customMenus: action.menus
    })),
    on(actions.SetReadOnlyAccess, (state, action) => ({
        ...state,
        readOnlyAccess: action.readOnlyAccess
    })),
    on(actions.OpenHelpFaqSearch, (state) => ({
        ...state,
        showHelpFaqSearch: true
    })),
    on(actions.CloseHelpFaqSearch, (state) => ({
        ...state,
        showHelpFaqSearch: false
    })),
    on(actions.SetCanEditFoldersPermission, (state, action) => ({
        ...state,
        canEditFoldersPermission: action.permission
    })),
    on(actions.SetCanDeleteFoldersPermission, (state, action) => ({
        ...state,
        canDeleteFoldersPermission: action.permission
    })),
    on(actions.SetEntitySuperUserView, (state, action) => ({
        ...state,
        entitySuperUserView: action.superUserView
    }))
);

export function reducer(state: UserInterfaceState | undefined, action: Action) {
    return applicationReducer(state, action);
}
