<div *ngIf="showUserMenu" class="user-menu" [ngClass]="{ 'expanded': expanded }" fxLayout="column" fxLayoutGap="16px" (click)="onMenuClick()">
    <div fxFlex="fill" fxLayout="column" class="links">
        <div *ngIf="client$ | async" class="portal-name">
            <strong class="font-size-l">{{(client$ | async).name}}</strong>
        </div>
        <div *ngIf="user?.clients?.length || user?.entityClients?.length || user?.isSuperUser" class="link position-relative">
            <div (click)="onSwitchAccountClick()" class="link-item">Switch Portal</div>
            <app-connect-tooltip id="client-portal-header-my-account-switch-account" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
        <div *ngIf="(client$ | async)?.userGroups?.length" class="link position-relative">
            <div (click)="onSwitchUserGroupClick()" class="link-item">Switch User Group</div>
            <app-connect-tooltip id="client-portal-header-my-account-switch-user-group" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
        <div *ngIf="(client$ | async)?.userGroups?.length && user?.isSuperUser" class="link position-relative">
            <div (click)="onClearUserGroupClick()" class="link-item">Clear User Group</div>
            <app-connect-tooltip id="client-portal-header-my-account-clear-user-group" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
        <div *ngIf="user" class="link position-relative">
            <div (click)="onEditProfileClick()" class="link-item">My User Details</div>
            <app-connect-tooltip id="client-portal-header-my-account-my-user-details" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div> 
        <div class="link position-relative">
            <div (click)="onShowTermsClick()" class="link-item">Terms &amp; Conditions</div>
            <app-connect-tooltip id="client-portal-header-my-account-terms-conditions" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
        <div *ngIf="showHelpFaqsMenu$ | async" class="link position-relative">
            <div (click)="onOpenHelpFaqSearchClick()" class="link-item">Help &amp; FAQs</div>
            <app-connect-tooltip id="client-portal-header-my-account-help-faqs" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
        <div class="link position-relative">
            <div (click)="onLogoutClick()" class="link-item"><i class="fa fa-light fa-sign-out"></i> Log out</div>
            <app-connect-tooltip id="client-portal-header-my-account-logout" (buttonClicked)="onPreventMenuClose()" position="left"></app-connect-tooltip>
        </div>
    </div>
</div>

<div *ngIf="showUserMenu" id="click-overlay" (click)="onMenuClick()"></div>