<div id="mobile-upload" *ngIf="initialised && !useIdvControl"> <!--[hidden]="!initialised || useIdvControl"-->
    <div *ngIf="!photoTaken" fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="start center">
        <div id="single-image-type" *ngIf="initialised">
            <div *ngIf="itemType === ItemType.Selfie">
                <app-idv-selfie-step (selfieTaken)="onImageTaken($event)"></app-idv-selfie-step>
            </div>
    
            <div *ngIf="itemType === ItemType.UniqueActionProcess">
                <app-idv-uap-step 
                    (uapTaken)="onImageTaken($event)"
                    [uapImageUrl]= "uapImage?.url"></app-idv-uap-step>
            </div>
    
            <div class="padding-32" *ngIf="itemType === ItemType.IdDocuments || itemType === ItemType.Image || itemType === ItemType.ImageList">
                <div fxLayout="column"fxLayoutGap="32px">
                    <div fxLayout="row" fxLayoutAlign="center center"><img fxFlex="191px" [src]="getExampleImageSrc()"/></div>
                    <div fxLayout="column" fxLayoutGap="16px">
                        <div class="text headline align-center">{{ getTitle() }}</div>
                        <div fxLayout="row" fxLayoutAlign="start top" fxLayoutGap="16px">
                            <mat-icon class="secondary-text">circle</mat-icon>
                            <div class="text body">Make sure the room is well lit.</div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start top" fxLayoutGap="16px">
                            <mat-icon class="secondary-text">circle</mat-icon>
                            <div class="text body">Make sure the document is in focus.</div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start top" fxLayoutGap="16px">
                            <mat-icon class="secondary-text">circle</mat-icon>
                            <div class="text body">Make sure there is no glare.</div>
                        </div>
                    </div>
                    <button class="btn large primary dark" (click)="onUploadClicked()" aria-label="Take Photo">Take Photo</button>
                </div>
            </div>
        </div>
    </div>
    <div class="hidden">
        <input #imageUpload accept="image/*" type="file" (change)="onUploadImage($event)" capture="environment" />
        <canvas #canvasElement ></canvas>
    </div> 
    <div class="padding-32" *ngIf="photoTaken && !imageSaved && !error" fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="start center">
        <app-image-card [canDownload]="false" [canRemove]="false" [src]="imageSrc"></app-image-card>
        <div fxLayout="column" fxLayoutGap="16px">
            <div class="text headline align-center">Looking good?</div>
            <div class="text sub-title align-center">If you're happy with your photo, click the button to confirm.</div>
        </div>
        <div fxLayout="column" fxLayoutGap="16px">
            <button class="btn large primary dark" (click)="onSubmitClicked()">Yes, looks good</button>
            <div class="text pointer body align-center" (click)="onRetakeClicked()">Retake</div>
        </div>
    </div>
    <app-loading-card *ngIf="savingImage$ | async" message="Please wait while your image is uploaded..."></app-loading-card>
    
    <div class="padding-32" fxLayoutAlign="center center">
        <!-- IMAGE HAS BEEN SAVED -->
        <div *ngIf="imageSaved" fxLayout="column" fxLayoutGap="32px">
            <div fxLayout="row" fxLayoutAlign="center center">
                <img fxFlex="191px" src="assets/idv/CheckCircleComplete.svg"/>
            </div>
            <div fxLayout="column" fxLayoutGap="16px">
                <div class="text headline align-center">Submitted</div>
                <div class="text sub-title align-center" *ngIf="processComplete">You can now close this browser tab and continue on the desktop application.</div>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" *ngIf="!processComplete">
                <div fxLayout="column" fxLayoutGap="16px">
                    <button class="btn large primary dark"  (click)="onAdditionalImageClicked()">Add another image</button>
                    <button class="btn large primary dark"  (click)="onReturnToDesktopClicked()">Return to desktop</button>                   
                </div>
            </div>
        </div>
    
        <div *ngIf="error" fxLayout="column" fxLayoutGap="32px">
            <!-- ERROR HAS OCCURRED - eg because the token is no longer valid -->
            <div fxLayout="row" fxLayoutAlign="center center">
                <img fxFlex="191px" src="assets/connect/icons/alert-high.svg"/>
            </div>
            <div fxLayout="column" fxLayoutGap="16px">
                <div class="text headline align-center">An error has occurred.</div>
                <div class="text sub-title align-center">Please return to the desktop and try again.</div>
            </div>
        </div>        
    </div>
</div>

<!-- IDV CONTROL -->
<div id="mobile-idv-upload" *ngIf="initialised && useIdvControl"><!--[hidden]="!initialised || !useIdvControl"-->
    <app-identity-verification
        [setId]="setId"
        [itemId]="itemId"
        [observationId]="observationId">
    </app-identity-verification>
</div>