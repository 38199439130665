<div class="id-documents-observation" fxLayout="column" fxLayoutGap="10px">
    <h3>{{item.description}}</h3>

    <div *ngIf="!idDocuments || !idDocuments.documents?.length && !addingDocument">No documents.</div>

    <div *ngIf="!addingDocument && !(uploading$ | async)">
        <button class="btn large primary dark" (click)="addDocument()">Add a document</button>
    </div>

    <div *ngIf="idDocuments && !addingDocument && !(uploading$ | async)" fxLayout="column" fxLayoutGap="6px">

        <app-id-documents-list *ngIf="!isClientUser" [organisationId]="itemObservation.ownerId" [idDocuments]="idDocuments"></app-id-documents-list>
        <app-id-documents-full-result *ngIf="isClientUser" [idDocuments]="idDocuments.documents" [urlImages]="true" [urls]="idDocumentUrls"></app-id-documents-full-result>

        <app-warning-container *ngIf="false && item.idDocumentsConfiguration.enforceRightToWorkRule && !idDocuments.rightToWorkDocumentsProvided">
            <!-- TODO - reinstate this message based on check-submitted-id-documents Credas API data -->
            You have not provided enough ID for the right to work check.
        </app-warning-container>
    </div>

    <div *ngIf="(uploading$ | async)">
        <app-loading-card message="Please wait while we upload and analyse your ID document..."></app-loading-card>
    </div>

    <div *ngIf="addingDocument" fxLayout="column" fxLayoutGap="10px">

        <h3>Add a new document</h3>
        <div fxLayout="row">
            <div fxFlex="100%" fxFlex.gt-sm="50%" fxlayout="column">
                <div class="font-question-label">ID Document Type</div>
                <mat-form-field appearance="outline">
                    <mat-select (selectionChange)="documentTypeSelectionChanged(documentTypeList.value)" #documentTypeList placeholder="ID Document Type">
                        <mat-option *ngFor="let documentType of item.idDocumentsConfiguration.enabledDocumentTypes" [value]="documentType">{{getIdDocumentTypeTitle(documentType)}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div [style.display]="selectedVis" fxLayout="row" fxLayoutAlign="start start" [hidden]="!addingIdDocumentType">
            <app-photo-capture 
                (imageUploaded)="imageUploaded($event)"
                (imageDownloadClicked)="imageDownloadClicked()"
                [downloadEnabled]="false"
                [disableUpload]="item.readonly"
                [convertToJpg]="false"
                [qrCodeData]="qrCodeData">
            </app-photo-capture>
        </div>
    </div>
</div>
