<div id="outreach-landing-page" fxLayout="column" fxLayoutAlign="center center">

    <div class="panel" fxLayout="column">
        <div class="header" fxFlex="100px" fxLayout="column" fxLayoutAlign="center center">
            <img src="/assets/logos/deloitte.png">
        </div>
        <div class="content" fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
            <div fxFlex>
                <p>In order to make a claim for compensation in respect of the land identified in the notice (as per the letter that you have received), you will need to answer some questions and provide some documentation to support your claim.</p>
                <p>You will need the registration code from the letter that we sent you.</p>
                <p>Please click '<strong>Continue</strong>' to get started.</p>
            </div>
            <img src="/assets/deloitte-letter.png">
        </div>
        <div class="footer" fxFlex="90px" fxLayout="row" fxLayoutAlign="space-between end">
            <img src="/assets/logos/credas-logo.png">
            <button class="btn large primary dark" [routerLink]="['/auth', 'register']">Continue</button>
        </div>
    </div>
</div>