import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Ngrx
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

// Store
import * as fromAuthActions from 'app/authentication/store/actions';
import * as fromAuth from 'app/authentication/store';
import * as fromAuthState from 'app/authentication/store/reducers';

// enums
import { TermsType } from 'app/shared/enums/terms-type.enum';

@Component({
    templateUrl: './terms-conditions-dialog.component.html'
})
export class TermsConditionsDialogComponent extends BaseComponent implements OnInit {
    termText: string = 'Loading, please wait.';

    constructor(public dialogRef: MatDialogRef<TermsConditionsDialogComponent>,
        private store: Store<fromAuthState.AuthenticationState>
    ) {
        super();
    }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getRegistrationTermsText))
            .subscribe((termText: string) => {
                if (termText) {
                    this.termText = termText;
                }
            });

        this.store.dispatch(new fromAuthActions.GetRegistrationTerm(TermsType.PlatformTermsOfUse));
    }
}
