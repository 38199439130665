<app-dialog id="zoho-export-dialog"
            dialogTitle="Export to Zoho"
            defaultWidth="500px"
            defaultHeight="750px"
            [showFooterActions]="true"
            [showClose]="false">

    <div body fxLayout="column" fxFlex>
        <div>
            <h3>Reference: <span>{{options.ownerId ? options.ownerId : 'None'}}</span></h3>
        </div>
        <div class="table-wrapper" *ngIf="hasFiles && dataSource">
            <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="filename">
                    <mat-header-cell *matHeaderCellDef fxFlex="*">File</mat-header-cell>
                    <mat-cell *matCellDef="let element" fxFlex="*">
                        {{ element.filename }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef fxFlex="150px">Status</mat-header-cell>
                    <mat-cell *matCellDef="let element" fxFlex="150px">
                        <span [class]="getExportStatusClass(element.status)">{{ getExportStatusText(element.status) }}</span>
                        <mat-progress-spinner *ngIf="element.status === exportStatus.InProgress" mode="indeterminate" [diameter]="20" class="status-progress"></mat-progress-spinner>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
        <div *ngIf="!hasFiles">
            <br />
            There aren't any files to export.
        </div>
    </div>

    <div footer-actions>
        <button class="btn large primary light" (click)="cancelClick()" [disabled]="cancelDisabled">{{ cancelLabel }}</button>
        <button class="btn large primary dark"  (click)="exportClick()" [disabled]="exportDisabled">Export</button>
    </div>
</app-dialog>