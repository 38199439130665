<mat-card class="profile-assessment-set" fxLayout="column">
    <div class="title-bar theme-text theme-background-8" fxLayout="row">
        <div class="title" fxLayoutAlign="start center" fxFlex="40">
            {{assessmentProfileSet.name}}
        </div>
        
        <div *ngIf="!assessmentProfileSet.mandatoryComplete" class="warning" fxLayout="row" fxFlex="15"
            fxLayoutAlign="space-between center">
            <div class="text" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center">
                <mat-icon>warning</mat-icon>
            </div>
        </div>

        <div class="expand-button" (click)="toggleExpanded()" fxFlex fxLayout="row" fxLayoutGap="15px"
            fxLayoutGap.lt-sm="5px" fxLayoutAlign="end center">
            <div *ngIf="assessmentProfileSet.isEditable" class="edit" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div>
                    <img src="/assets/icons/edit-grey.png" (click)="$event.stopPropagation(); onShowAssessmentSet()" />
                </div>
            </div>

            <mat-icon class="expand" [ngClass]="{'expanded': assessmentProfileSet.expanded}">keyboard_arrow_down
            </mat-icon>
        </div>
    </div>
    <div class="content" fxLayout="column" [@slideInOut]="assessmentProfileSet.expanded ? 'show' : 'hide'">
        <div *ngIf="assessmentProfileSet.expanded" fxLayout="column">
            <div class="item" fxLayout="row" fxFlex="100" *ngFor="let item of assessmentProfileSet.items"
                [ngClass]="{'has-alerts': item.hasAlerts, 'required': !item.hasAlerts && item.mandatory && !item.hasValue, 'separator button-class-border-bottom-2px': item.type | isItemType : ItemType.Separator, 'advice-text': item.type | isItemType : ItemType.AdviceText }">

                <div fxFlex *ngIf="item.type | isItemType:ItemType.Separator">
                    <div class="label button-class-text">{{ item.name }}</div>
                </div>

                <div fxFlex *ngIf="item.type | isItemType : ItemType.AdviceText">
                    <div class="advice">
                        <pre [innerHtml]="item.description"></pre>
                    </div>
                </div>

                <div fxFlex fxLayout="row"
                    *ngIf="!(item.type | isItemType : ItemType.Separator) && !(item.type | isItemType : ItemType.AdviceText)"
                    fxLayoutAlign="start start">
                    <div fxFlex="40" class="label" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <div>
                            {{item.name}}<span *ngIf="item.mandatory"> *</span>
                        </div>
                        <mat-icon *ngIf="item.hasAlerts">warning</mat-icon>
                    </div>
                    <div fxFlex class="value" fxLayout="column" fxLayoutGap="14px">
                        <div>
                            <div *ngFor="let observation of item.observations">
                                <div *ngIf="item.confidential">
                                    <div>{{observation.displayValue}}</div>
                                    <div *ngFor="let information of observation.extraInformation">
                                        {{information}}
                                    </div>
                                </div>
                                <div *ngIf="!item.confidential">
                                    <div *ngIf="!observation.showDownloadLink && !(observation | canShowObservationImage : item)">
                                        <app-view-postcode-observation *ngIf="item.type === ItemType.Postcode" 
                                            [observationValue]="observation.displayValue">
                                        </app-view-postcode-observation>
                                        <div *ngIf="item.type !== ItemType.Postcode && item.type !== ItemType.IdDocuments && item.type !== ItemType.RichText && item.type !== ItemType.CourseSelector && item.type !== ItemType.IdentityVerification">{{observation.displayValue}}</div>
                                        <div *ngIf="item.type === ItemType.RichText" [innerHTML]="observation.displayValue | safe: 'html'"></div>
                                        <div *ngIf="item.type === ItemType.CourseSelector">{{getCourseTitle(observation)}}</div>
                                        <app-id-documents-list *ngIf="item.type === ItemType.IdDocuments && !isClientUser" [organisationId]="assessmentProfileSet.ownerId" [idDocumentsJson]="observation.displayValue"></app-id-documents-list>
                                        <app-id-documents-full-result *ngIf="item.type === ItemType.IdDocuments && isClientUser" [idDocumentsJson]="observation.displayValue" [urlImages]="true" [urls]="idDocumentUrls" [urlsLoading$]="idDocumentUrlsLoading$"></app-id-documents-full-result>
                                        <app-identity-verification-data *ngIf="item.type === ItemType.IdentityVerification" [observationId]="observation.id" [idvDataJson]="observation.displayValue"></app-identity-verification-data>
                                    </div>
                                    <div *ngIf="(observation | canShowObservationImage : item) && hasImage(observation)">
                                        <img [src]="observation['base64Image'] | safe: 'resourceUrl'" />
                                    </div>
                                    <div *ngIf="observation.showDownloadLink">
                                        <a (click)="downloadFile($event, item, observation)">{{observation.displayValue}}</a>
                                    </div>
                                    <div *ngFor="let information of observation.extraInformation">
                                        {{information}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>
