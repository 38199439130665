<div class="idv-complete-step" fxLayout="column"fxLayoutGap="32px">
    <div fxLayout="row" fxLayoutAlign="center center"><img fxFlex="150px" src="assets/idv/CheckCircleComplete.svg"/></div>
    <div fxLayout="column" fxLayoutGap="16px">
        <div class="text headline align-center">Submitted</div>
        <div class="text sub-title align-center">Your Identity Verification has been submitted.</div>
    </div>
    <div fxLayout="column" fxLayoutGap="16px" *ngIf="!returnedToDesktop">
        <div fxLayout="column" fxLayoutGap="16px">
            <button class="btn large primary dark" *ngIf="configuration.captureSelfie && showRetakeButtons" (click)="handleRetakeSelfieClick()">Retake Selfie</button>
            <button class="btn large primary dark" *ngIf="configuration.captureIdDocuments && showRetakeButtons" (click)="handleAddIdDocumentClicked()">Add ID Documents</button>
            <button class="btn large primary dark" *ngIf="(isMobileDevice$ | async) && showReturnToDesktop" (click)="handleCloseSwitchToMobile()">Return to desktop</button>
        </div>
    </div>
    <div fxLayout="column" fxLayoutGap="16px" *ngIf="returnedToDesktop">
        You can now close this browser tab and continue on the desktop application.   
    </div>
</div>