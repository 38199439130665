// ngrx
import { createReducer, on, Action } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions/branding.actions';

// models
import { Branding } from 'app/models/branding.model';
import { environment } from 'environments/environment';
import { EntityBranding } from 'app/branding/modules/entity-branding/models/entity-branding.model';
import { MagicLinkType } from 'app/branding/modules/entity-branding/enumerations/magic-link-type.enum';

const credasBranding: Branding = {
    id: undefined,
    supportEmail: 'support@credas.com',
    supportText: 'Need some help? Contact support: support@credas.com',
    logoUrl: 'assets/credas/credas-logo.png',
    credasLogoColor: '#FFFFFF',
    backgroundUrl: 'assets/credas/credas-background.png',
    textColor: '#FFFFFF',
    primaryColor: '#323343',
    primaryReverseColor: '#FFFFFF',
    secondaryColor: '#FCAD2D',
    secondaryColorDark: '#DB9F3E',
    accentColor: '#F1A948',
    title: 'Powering Businesses Remotely',
    tagLine: 'Onboarding | Anti-Money Laundering | Data Capture',
    authenticationLogoUrl: 'assets/credas/credas-logo.png',
    favIcon: getProperty('favIcon', 'favicon.ico'),
    headerColor: '#323343',
    tableAndTabColor: '#323343',
    tableAndTabTextColor: '#FFFFFF'
};

const connectBranding: Branding = {
    id: undefined,
    supportEmail: null,
    supportText: null,
    logoUrl: getProperty('logoUrl', 'assets/connect/connect-logo.svg'),
    credasLogoColor: getProperty('credasLogoColor', '#FFFFFF'),
    backgroundUrl: getProperty('backgroundUrl', 'assets/connect/connect-background.png'),
    textColor: getProperty('textColor', '#FFFFFF'),
    primaryColor: getProperty('primaryColor', '#323343'),
    primaryReverseColor: getProperty('primaryReverseColor', '#FFFFFF'),
    secondaryColor: getProperty('secondaryColor', '#31B7BE'),
    secondaryColorDark: getProperty('secondaryColorDark', '#24888C'),
    accentColor: getProperty('accentColor', '#F1A948'),
    title: getProperty('title', 'Identity Verification Made Simple'),
    tagLine: getProperty('tagLine', 'Credas are the only identity verification provider to be accredited by HMRC for Regulatory Excellence'),
    authenticationLogoUrl: getProperty('authenticationLogoUrl', 'assets/connect/connect-powered-by-credas-logo.svg'),
    favIcon: getProperty('favIcon', 'assets/connect/favicon.ico'),
    headerColor: '#323343',
    tableAndTabColor: getProperty('tableAndTabColor', '#323343'),
    tableAndTabTextColor: getProperty('tableAndTabTextColor', '#FFFFFF')
};


const entityBranding: EntityBranding = {
    clientName: null,
    accentColor: getProperty('entityAccentColor', '#31B7BE'),
    authenticationBackgroundUrl: getProperty('entityAuthenticationBackgroundUrl', 'assets/connect/connect-background.png'),
    authenticationHeader: getProperty('entityAuthenticationHeader', 'Identity Verification Made Simple'),
    authenticationLogoUrl: getProperty('entityAuthenticationLogoUrl', 'assets/connect/connect-powered-by-credas-logo.svg'),
    authenticationTagline: getProperty('entityAuthenticationTagline', 'Credas are the only identity verification provider to be accredited by HMRC for Regulatory Excellence.'),
    bannerButtonColor: getProperty('entityBannerButtonColor', '#323343'),
    bannerButtonLink: getProperty('entityBannerButtonLink', 'https://www.credas.co.uk/contact'),
    bannerButtonText: getProperty('entityBannerButtonText', 'Find out more'),
    bannerButtonTextColor: getProperty('entityBannerButtonTextColor', '#FFFFFF'),
    bannerEnabled: getProperty('entityBannerEnabled', 'true') === 'true',
    bannerHeader: getProperty('entityBannerHeader', 'Identity Verification Made Simple'),
    bannerHeaderColor: getProperty('entityBannerHeaderColor', '#FFFFFF'),
    bannerImageUrl: getProperty('entityBannerImageUrl', 'assets/credas/credas-banner-bg.png'),
    bannerText: getProperty('entityBannerText', 'Credas is an affordable identity and ID document verification solution, enabling businesses across multiple markets and sectors to onboard and verify people – quickly and accurately.'),
    bannerTextColor: getProperty('entityBannerTextColor', '#FFFFFF'),
    credasLogoColor: getProperty('entityCredasLogoColor', '#FFFFFF'),
    helpText: getProperty('entityHelpText', ''),
    logoUrl: getProperty('entityLogoUrl', 'assets/connect/connect-logo.svg'),
    primaryButtonColor: getProperty('entityPrimaryButtonColor', '#31B7BE'),
    primaryButtonTextColor: getProperty('entityPrimaryButtonTextColor', '#323343'),
    primaryColor: getProperty('entityPrimaryColor', '#323343'),
    primaryTextColor: getProperty('entityPrimaryTextColor', '#FFFFFF'),
    secondaryButtonColor: getProperty('entitySecondaryButtonColor', '#323343'),
    secondaryButtonTextColor: getProperty('entitySecondaryButtonTextColor', '#FFFFFF'),
    tableAndTabColor: getProperty('entityTableAndTabColor', '#323343'),
    tableAndTabTextColor: getProperty('entityTableAndTabTextColor', '#FFFFFF'),
    textColor: getProperty('entityTextColor', '#323343'),
    magicLinkType: MagicLinkType.CredasDashboard
};

export interface BrandingState {
    branding: Branding;
    credasBranding: Branding;
    connectBranding: Branding;
    entityBranding: EntityBranding;
    isConnect: boolean;
}

const initialState: BrandingState = {
    branding: null,
    credasBranding,
    connectBranding,
    entityBranding,
    isConnect: false
};

const brandingReducer = createReducer(
    initialState,
    on(actions.SetBranding, (state, action) => ({
        ...state,
        branding: action.branding
    })),
    on(actions.ClearBranding, (state) => ({
        ...state,
        branding: null
    })),
    on(actions.SetVersion, (state, action) => ({
        ...state,
        isConnect: action.isConnect
    })),
    on(actions.GetDefaultBrandingSuccess, (state, action) => ({
        ...state,
        credasBranding: action.branding
    }))
);

export function reducer(state: BrandingState | undefined, action: Action) {
    return brandingReducer(state, action);
}

function getProperty(name: string, defaultValue: string): string {

    if (environment[name] && (<string>environment[name]).length > 0 && !(<string>environment[name]).endsWith('#')) {
        return <string>environment[name];
    }

    return defaultValue;
}