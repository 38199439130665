export enum Modules {

    // Next module id to be 243 (please keep this up to date)
    /* CONNECT PORTAL */
    /* - APP */ ConnectPortal = 70, /* Connect Portal */
    /*   - V2 MODULE */ ConnectPortalProcesses = 150, /* Allows the user to see the list of Processes for the Client */
    /*     - V2 PERMISSION */ ConnectPortalProcessExport = 171, /* Allows the user to export Processes */
    /*     - V2 PERMISSION */ ConnectPortalProcessCreate = 185, /* Allows the user to create Processes */
    /*     - V2 PERMISSION */ ConnectPortalProcessCreateFolder = 186, /* Allows the user to create folders for Processes */
    /*     - V2 PERMISSION */ ConnectPortalProcessAddToFolder = 187, /* Allows the user to add Processes to folders */
    /*     - V2 PERMISSION */ ConnectPortalProcessAutoRefresh = 188, /* Allows the user to turn on auto-refresh on the Processes list */
    /*     - V2 PERMISSION */ ConnectPortalProcessExportToCsv = 189, /* Allows the user to export Processes to CSV */
    /*     - V2 PERMISSION */ ConnectPortalProcessDelete = 190, /* Allows the user to delete Processes */
    /*     - V2 PERMISSION */ ConnectPortalProcessLock = 191, /* Allows the user to lock Processes */
    /*     - V2 PERMISSION */ ConnectPortalProcessLockQuestion = 192, /* Allows the user to lock questions on Processes */
    /*     - V2 PERMISSION */ ConnectPortalProcessViewStatusPanel = 226, /* Allows the user to view the status panel on Processes */
    /*     - V2 PERMISSION */ ConnectPortalProcessViewActorPanel = 227, /* Allows the user to view the actor panel on Processes */
    /*     - V2 PERMISSION */ ConnectPortalProcessViewValidationPanel = 228, /* Allows the user to view the validation summary on Processes */
    /*     - V2 MODULE */ ConnectPortalEntities = 151, /* Allows the user to see the list of Entities for the Client */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesAdmin = 159, /* Allows the user to create Entities */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesEvidence = 181, /* Allows the user to view and add entity evidence */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesInvite = 193, /* Allows the user to invite entities to a process */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesCreateFolder = 194, /* Allows the user to create folders for entities */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesAddToFolder = 195, /* Allows the user to add entities to folders */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesAutoRefresh = 196, /* Allows the user to turn on auto-refresh on the Entities list */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesExportToCsv = 197, /* Allows the user to export entities to CSV*/
    /*     - V2 PERMISSION */ ConnectPortalEntitiesEditUserDetails = 198, /* Allows the user to edit entity user's details */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesDelete = 199, /* Allows the user to delete entities */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesAddDataCheck = 200, /* Allows the user to add data checks to entities */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesAddNote = 201, /* Allows the user to add notes to entities */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesCreateTag = 202, /* Allows the user to create tags for entities */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesAddTag = 203, /* Allows the user to add tags to entities */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesMilestones = 213, /* Allows the user to view entity milestones */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesProcesses = 220, /* Allows the user to view entity processes */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesIdentityChecks = 221, /* Allows the user to view entity identity checks */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesDocuments = 222, /* Allows the user to view entity documents */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesNotes = 223, /* Allows the user to view entity notes */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesPdfExports = 224, /* Allows the user to view entity PDF exports */
    /*     - V2 PERMISSION */ ConnectPortalEntitiesExportEntityToPdf = 234, /* Allows the user to export entity to PDF*/
    /*   - V2 MODULE */ ConnectPortalInvestigateCase = 166, /* Allows the user to perform "investigate case" */
    /*   - V1 MODULE */ ConnectPortalEntitiesv1 = 21, /* Allows the user to see the list of Entities for the Client */
    /*     - V1 PERMISSION */ ConnectPortalEntitiesCreateFolderv1 = 130, /* Allows the user to create folders / lists for entities */
    /*     - V1 PERMISSION */ ConnectPortalEntitiesAdminv1 = 74, /* Allows the user to Add Entities to a Client*/
    /*     - V1 PERMISSION */ ConnectPortalEntitiesImport = 121, /* Allows the user to import entities from CSV */
    /*     - V1 PERMISSION */ ConnectPortalEntitiesZohoExport = 144, /* Allows the user to run the ZOHO extract for an entity */
    /*     - V1 PERMISSION */ ConnectPortalEntitiesNotesV1 = 122, /* Allows the user to administer notes for an entity */
    /*   - V1 MODULE */ ConnectPortalDocumentsV1 = 20, /* Allows the user to see/edit Documents */
    /*   - V1 MODULE */ ConnectPortalRoadmap = 148, /* Allows the user to view the Connect roadmap */
    /*   - V1 MODULE */ ConnectPortalUserManagement = 83, /* Manage users within a client */
    /*   - V1 MODULE */ UserManagementSubContractors = 147, /* Manage sub-contractors for a client */
    /*   - V2 MODULE */ ConnectPortalDocuments = 174, /* Allows the user to view the document library */
    /*     - V2 PERMISSION */ ConnectPortalDocumentsDownload = 216, /* Allows the user to download documents from the library */
    /*   - V2 MODULE */ ConnectPortalSettings = 231, /* Allows users to manage portal settings */

    /* CONNECT MANAGEMENT */
    /* - APP */ ConnectManagement = 111, /* The user can view the connect management app */
    /*   - V1 MODULE */ ConnectManagementClientsv1 = 86, /* Manage connect clients */
    /*   - V1 MODULE */ ConnectManagementTermsv1 = 118, /* Manage connect terms */
    /*   - SHARED MODULE */ ConnectManagementSuperUsers = 119, /* Manage connect super users */
    /*   - V1 MODULE */ ConnectManagementApplicationsv1 = 95, /* Manage connect applications, services and permissions */
    /*   - V2 MODULE */ ConnectManagementClients = 156, /* Manage connect clients */
    /*   - V2 MODULE */ ConnectManagementEndUsers = 225, /* Manage connect end users */
    /*   - V2 MODULE */ ConnectPortalUsers = 154, /* Manage users within a client */
    /*     - V2 PERMISSION */ ConnectPortalUsersCreate = 204, /* Allows the user to create users */
    /*     - V2 PERMISSION */ ConnectPortalUsersEdit = 212, /* Allows the user to edit users */
    /*     - V2 PERMISSION */ ConnectPortalUsersCopyResetLink = 205, /* Allows the user to copy reset password links */
    /*     - V2 PERMISSION */ ConnectPortalUsersSendResetEmail = 206, /* Allows the user to send reset password emails */
    /*     - V2 PERMISSION */ ConnectPortalUsersSetTemporaryPassword = 207, /* Allows the user to set temporary passwords */
    /*     - V2 PERMISSION */ ConnectPortalUsersReissueApiKey = 208, /* Allows the user to re-issue API keys */
    /*     - V2 PERMISSION */ ConnectPortalUsersRevokeApiKey = 209, /* Allows the user to revoke API keys */
    /*     - V2 PERMISSION */ ConnectPortalUsersCopyApiKey = 210, /* Allows the user to copy API keys */
    /*     - V2 PERMISSION */ ConnectPortalUsersRemove = 211, /* Allows the user to remove users */
    /*   - V2 MODULE */ ConnectPortalUserGroups = 167, /* Manage user groups within a client */
    /*     - V2 PERMISSION */ ConnectPortalUserGroupsCreate = 217, /* Allows the user to create user groups */
    /*     - V2 PERMISSION */ ConnectPortalUserGroupsRemove = 215, /* Allows the user to remove user groups */
   /*   - V2 MODULE */ ClientManagementClients = 170, /* User can administer reseller clients */
    /*     - V2 PERMISSION */ ClientManagementClientsAddResellerChild = 218, /* User can add reseller clients */
    /*     - V2 PERMISSION */ ClientManagementClientsEditResellerChild = 219, /* User can edit reseller clients */
    /*   - V1 MODULE */ ConnectManagementTelephoneNumbersv1 = 229, /* Allows the user to edit the SMS sender telephone numbers */

    /* CUSTOMISATION CENTRE */
    /* - APP */ CustomisationCentre = 114, /* Allows the user to view the customisation centre */
    /*   - V1 MODULE */ CustomisationCentreFormsv1 = 22, /* Allows the user to administer forms */
    /*   - V2 MODULE */ CustomisationCentreForms = 152, /* Allows the user to administer forms */
    /*   - V1 MODULE */ CustomisationCentreJourneysv1 = 115, /* Allows the user to administer processes */
    /*   - V2 MODULE */ CustomisationCentreJourneys = 153, /* Allows the user to administer processes */
    /*     - V2 PERMISSION */ CustomisationCentreJourneysOpenBankingSteps = 239, /* Allows the user to add open banking steps to journeys */
    /*   - V2 MODULE */ CustomisationCentreInformationPages = 149, /* Allows the user to administer information pages */
    /*   - V1 MODULE */ ConnectPortalTemplateLibrary = 136, /* Allows the user to view the Client template library */
    /*     - V1 PERMISSION */ ConnectPortalTemplateLibraryCreate = 139, /* Allows the user to add templates to Library */
    /*     - V1 PERMISSION */ ConnectPortalTemplateLibraryDelete = 143, /* Allows the user to delete Templates from their Library */
    /*   - V2 MODULE */ CustomisationCentreTemplates = 158, /* Allows the user to view the Client template library */
    /*   - V2 MODULE */ CustomisationCentreLists = 165, /* Allows the user to edit lists */
    /*   - V2 MODULE */ CustomisationCentreRedirectUrls = 232, /* Allows users to add a redirect url as a journey step */

    /* BRANDING */
    /* - APP */ Branding = 172, /* Allows the user to view the branding application */
    /*   - V2 MODULE */ BrandingEntityBranding = 161, /* Allows the user to edit the entity portal branding */
    /*   - V2 MODULE */ BrandingClientBranding = 162, /* Allows the user to edit the client portal branding */
    /*   - V2 MODULE */ BrandingClientAdverts = 163, /* Allows the user to edit the client adverts */
    /*   - V2 MODULE */ CustomisationCentreSmsTemplates = 173, /* Allows the user to edit lists */
    /*   - V2 MODULE */ CustomisationCenterEmailTemplate = 175, /* Allows the user to edit customisable email templates */

    /* INFO */
    /* - APP */ ConnectInfo = 177, /* Allows the user to view all kinds of info content */
    /*   - V2 MODULE */ ConnectInfoFeatureRequests = 178, /* Allows the client to view feature requests */
    /*   - V2 MODULE */ ConnectInfoReleaseNotes = 182, /* Allows the client to view active release notes */
    /*   - V2 MODULE */ ConnectInfoRoadmaps = 184, /* Allows the client to view active roadmaps */
    /*   - V2 MODULE */ ConnectInfoGlossary = 235, /* Allows the client to view active glossary */
    /*   - V2 MODULE */ ConnectInfoGuides = 237, /* Allows the client to view active guide */

    /* PRODUCT CONFIGURATION */
    /* - APP */ ProductConfiguration = 179, /* Allows the user to make system wide configuration */
    /*   - V2 MODULE */ ProductConfigurationSystemAdverts = 164, /* Allows the user to edit the system adverts */
    /*   - V2 MODULE */ ProductConfigurationEmailTemplateTypes = 176, /* Allows the user to edit email template types */
    /*   - V2 MODULE */ ProductConfigurationReleaseNotes = 180, /* Allows super users to publish release notes via Connect rather than via email */
    /*   - V2 MODULE */ ProductConfigurationRoadmaps = 183, /* Allows super users to publish roadmaps */
    /*   - V2 MODULE */ ProductConfigurationGlossary = 236, /* Allows super users to publish glossary */
    /*   - V2 MODULE */ ProductConfigurationGuides = 238, /* Allows super users to publish guide */
    /*   - V2 MODULE */ ProductConfigurationDefaultEmails = 242, /* Allows super users to set up default emails */

    /* SYSTEM CONFIGURATION */
    /* - APP */ SystemConfiguration = 243, /* Allows the user to make system wide configuration */
    /*   - V2 MODULE */ ProductConfigurationTooltips = 214, /* Allows super users to publish tooltips, to help users navigate the system */
    /*   - V2 MODULE */ ConnectManagementTerms = 157, /* Manage connect terms */
    /*   - V2 MODULE */ ProductConfigurationHelpFaqs = 168, /* Allows the user to search for help and faq items */
    /*   - V2 MODULE */ ProductConfigurationTelephoneNumbers = 240, /* Allows super users to publish telephone numbers */
    /*   - V2 MODULE */ ProductConfigurationNonBiometricDocumentTypes = 241, /* Allows super users to set up non-biometric document types */
    /*   - V2 MODULE */ ConnectManagementApplications = 160, /* Manage connect applications, services and permissions */
    /*   - V2 MODULE */ ProductConfigurationPackages = 230, /* Allows super users to administer packages */
}
