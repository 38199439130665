import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from 'ngx-color-picker';

// Components
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { LoadingCardComponent } from './components/loading-card/loading-card.component';
import { AlertComponent } from './components/alert/alert.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { PhotoCaptureComponent } from './components/photo-capture/photo-capture.component';
import { TermsConditionsDialogComponent } from './components/terms-conditions-dialog/terms-conditions-dialog.component';
import { PrivacyPolicyDialogComponent } from './components/privacy-policy-dialog/privacy-policy-dialog.component';
import { UsageDialogComponent } from './components/usage-dialog/usage-dialog.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ConfirmationRequiredDialogComponent } from './components/confirmation-required-dialog/confirmation-required-dialog.component';
import { TrueFalseTickIconComponent } from './components/true-false-tick-icon/true-false-tick-icon.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DialogComponent } from './components/dialog/dialog/dialog.component';
import { DialogFieldComponent } from './components/dialog/dialog-field/dialog-field.component';
import { DialogFieldInformationComponent } from './components/dialog/dialog-field-information/dialog-field-information.component';
import { OptionSelectorComponent } from './components/option-selector/option-selector.component';
import { PagingNavigationComponent } from './components/paging-navigation/paging-navigation.component';
import { CardContainerComponent } from './components/card-container/card-container.component';
import { WarningContainerComponent } from './components/warning-container/warning-container.component';
import { NoInformationMessageComponent } from './components/no-information-message/no-information-message.component';
import { SectionWrapperComponent } from './components/section-wrapper/section-wrapper.component';
import { UserActivityComponent } from './components/user-activity/user-activity.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { SupportTextComponent } from './components/support-text/support-text.component';
import { HeaderComponent } from './components/header/header.component';
import { AdviceTextComponent } from './components/advice-text/advice-text.component';
import { PageInformationComponent } from './components/page-information-text/page-information-text.component';
import { IdDocumentsListComponent } from './components/id-documents-list/id-documents-list.component';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { SecureImageComponent } from './components/secure-image/secure-image.component';
import { SecureBackgroundImageComponent } from './components/secure-image/secure-background-image.component';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { SignatureDialogComponent } from './components/signature-dialog/signature-dialog.component';
import { CanvasComponent } from './components/canvas/canvas.component';
import { IdentityVerificationDataComponent } from './components/identity-verification-data/identity-verification-data.component';
import { IdDocumentsFullResultComponent } from './components/id-documents-full-result/id-documents-full-result.component';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { SearchableInputComponent } from './components/searchable-input/searchable-input.component';
import { SearchableMultiSelectInputComponent } from './components/searchable-multi-select-input/searchable-multi-select-input.component';
import { SessionTimeoutDialogComponent } from './components/session-timeout-dialog/session-timeout-dialog.component';
import { ColorPickerDialogComponent } from './components/color-picker-dialog/color-picker-dialog.component';
import { InfoTitleComponent } from 'app/shared/components/info-title/info-title.component';
import { AccordionComponent } from './components/accordion/accordion.component';

// Directives
import { ModuleVisibleDirective } from './directives/module-visible.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { EmptyToNullDirective } from './directives/empty-to-null.directive';

// Modules
import { PlatformMaterialModule } from './platform-material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFittextModule } from 'angular-fittext';

// Pipes
import { SafePipe, SortCodeFormatPipe, OrderByPipe, UkDatePipe, CanDisplayDocumentMenu, DocumentSidePipe } from './pipes';
import { IsItemTypePipe } from './pipes/is-item-type.pipe';
import { IsMatchingStringPipe } from './pipes/is-matching-string.pipe';
import { ItemDescriptionPipe } from './pipes/item-description.pipe';
import { ItemLabelPipe } from './pipes/item-label.pipe';
import { IsItemTypeListPipe } from './pipes/is-item-type-list.pipe';
import { IsItemTypeRTWPipe } from './pipes/is-item-show-label.pipe';
import { ModuleIsEnabledPipe } from './pipes/module-is-enabled.pipe';
import { SignatureTypeDescriptionPipe } from './pipes/signature-type-description.pipe';
import { ModuleTitlePipe } from './pipes/module-title.pipe';

// Services
import { AlertService } from './components/alert/services/alert.service';
import { OrganisationsService, UserService, ValidationService, DeviceDetectionService, PdfViewerService } from './services';
import { ExternalReferencesService } from './services/external-references.service';
import { ImageUploadService } from './services/image-upload.service';
import { VideoCaptureService } from './services/video-capture.service';
import { EventTrackingService } from './services/event-tracking.service';
import { ModuleService } from './services/module.service';
import { LegalDialogsService } from './services/legal-dialogs.service';
import { UserActivityService } from './services/user-activity.service';
import { AddressLookupService } from './services/address-lookup.service';
import { PagingService } from './services/paging.service';
import { SetTemplateGroupsService } from './services/set-template-groups.service';
import { EmailTemplatesService } from './services/email-templates.service';
import { HandlebarsService } from './services/handlebars.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';

// Extensions
import 'app/shared/extensions/date.extension';
import 'app/shared/extensions/array.extension';


const components = [
    ColorPickerComponent,
    LoadingCardComponent,
    AlertComponent,
    FileUploadComponent,
    ImageUploadComponent,
    PhotoCaptureComponent,
    TermsConditionsDialogComponent,
    PrivacyPolicyDialogComponent,
    UsageDialogComponent,
    PageNotFoundComponent,
    ConfirmationRequiredDialogComponent,
    TrueFalseTickIconComponent,
    DateInputComponent,
    DialogComponent,
    DialogFieldComponent,
    DialogFieldInformationComponent,
    OptionSelectorComponent,
    PagingNavigationComponent,
    CardContainerComponent,
    WarningContainerComponent,
    SectionWrapperComponent,
    NoInformationMessageComponent,
    UserActivityComponent,
    InformationDialogComponent,
    HeaderComponent,
    SupportTextComponent,
    AdviceTextComponent,
    PageInformationComponent,
    DisableControlDirective,
    IdDocumentsListComponent,
    ImagePopupComponent,
    SecureBackgroundImageComponent,
    SecureImageComponent,
    SecureBackgroundImageComponent,
    ImageCardComponent,
    FileUploadDialogComponent,
    SignatureDialogComponent,
    CanvasComponent,
    IdentityVerificationDataComponent,
    IdDocumentsFullResultComponent,
    ActivityLogComponent,
    CircleProgressComponent,
    SearchableInputComponent,
    SearchableMultiSelectInputComponent,
    SessionTimeoutDialogComponent,
    ColorPickerDialogComponent,
    InfoTitleComponent,
    AccordionComponent
];

const pipes = [
    SafePipe,
    SortCodeFormatPipe,
    OrderByPipe,
    IsItemTypePipe,
    ItemDescriptionPipe,
    ItemLabelPipe,
    IsMatchingStringPipe,
    IsItemTypeListPipe,
    IsItemTypeRTWPipe,
    UkDatePipe,
    DocumentSidePipe,
    CanDisplayDocumentMenu,
    ModuleIsEnabledPipe,
    SignatureTypeDescriptionPipe,
    ModuleTitlePipe
];

const directives = [
    ModuleVisibleDirective,
    AutoFocusDirective,
    EmptyToNullDirective,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PlatformMaterialModule,
        ReactiveFormsModule,
        ClipboardModule,
        TranslateModule,
        DragDropModule,
        AngularFittextModule,
        ColorPickerModule
    ],
    declarations: [
        ...components,
        ...pipes,
        ...directives
    ],
    providers: [
        UserService,
        OrganisationsService,
        ExternalReferencesService,
        AlertService,
        ValidationService,
        DeviceDetectionService,
        ImageUploadService,
        VideoCaptureService,
        PdfViewerService,
        EventTrackingService,
        ModuleService,
        LegalDialogsService,
        UserActivityService,
        AddressLookupService,
        PagingService,
        SetTemplateGroupsService,
        EmailTemplatesService,
        HandlebarsService,
        GoogleTagManagerService
    ],
    exports: [
        ...components,
        ...pipes,
        ...directives,
        ReactiveFormsModule,
        PlatformMaterialModule,
        TranslateModule,
        RouterModule
    ],
    entryComponents: [
        TermsConditionsDialogComponent,
        PrivacyPolicyDialogComponent,
        UsageDialogComponent,
        ConfirmationRequiredDialogComponent,
        InformationDialogComponent,
        AlertComponent,
        ImagePopupComponent,
        SecureBackgroundImageComponent,
        SecureImageComponent,
        SecureBackgroundImageComponent,
        FileUploadDialogComponent,
        SignatureDialogComponent,
        CanvasComponent,
        LoadingCardComponent,
        SessionTimeoutDialogComponent,
        ColorPickerDialogComponent
    ]
})

export class SharedModule { }