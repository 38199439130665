<header *ngIf="(showHeader$ | async)" class="connect-header">
    <div class="inset-container position-relative">
        <div fxLayout="row" fxLayoutAlign="start start">
            <div fxLayout="column" fxFlex class="overflow-hidden">
                <div fxLayout="row">
                    <img *ngIf="logoUrl" class="logo pointer" [src]="logoUrl | safe: 'resourceUrl'" (click)="onLogoClicked()"/>
                    <ng-template [ngIf]="applications$ | async">
                        <span class="header-separator">|</span>
                        <a [matMenuTriggerFor]="applicationsMenu" class="applications-menu">
                            <span class="secondary-text">
                                <strong>{{ currentApplication.title }}</strong>
                            </span>
                            <i class="fa fa-light fa-angle-down"></i>
                        </a>
                        <mat-menu #applicationsMenu="matMenu" data-auto="header-applications-menu">
                            <a *ngFor="let application of applications$ | async" mat-menu-item (click)="onApplicationChange(application.id)">{{ application.title }}</a>
                        </mat-menu>
                    </ng-template>
                    <nav fxFlex fxLayout="row">
                        <a *ngFor="let service of services$ | async"
                            [class.active]="service.id === (service$ | async)?.id"
                            (click)="onServiceClick(service.id)"
                            [title]="service.description">{{service.title}}</a>
                    </nav>
                </div>
            </div>
            <div fxLayout="row" class="actions" fxLayoutAlign="end" fxLayoutGap="30px">
                <a class="hide"><i class="fa fa-light fa-search"></i></a>
                <a *ngIf="showTooltipsTour$ | async" (click)="onShowTooltipsTourClick()"><i class="fa fa-light fa-circle-info"></i></a>
                <a class="menu-button border-radius-5" [title]="userName" (click)="onUserMenuClick()">{{userInitials}}</a>
            </div>
        </div>
        <app-user-menu></app-user-menu>
    </div>
</header>
