<div fxFlex fxLayout="column" class="esign-signature">
    <div fxFlex fxLayout="row" *ngIf="signed" fxLayoutAlign="start center" fxLayoutGap="24px" class="signed-information">
        <mat-icon>check_circle</mat-icon>
        <div fxFlex fxLayout="column">
            <div fxFlex class="signature">Signed by <strong>{{signatureName}}</strong></div>
            <div>on <strong>{{signatureDate | date: 'dd MMMM yyyy' }}</strong> @ <strong>{{signatureDate | date: 'HH:mm:ss'}}</strong></div>
        </div>
    </div>

    <div *ngIf="!signed" fxLayout="row" fxLayoutAlign="center center">
        <button class="btn large primary dark sign-button" (click)="sign()">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div>Sign this document</div>
                <mat-icon>check_circle</mat-icon>
            </div>
            
        </button>
    </div>
    
</div>