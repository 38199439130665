import { Action, createAction, props } from '@ngrx/store';

// Models
import { TokenResponse, RefreshTokenRequest } from 'app/shared/models';
import { InviteDetails } from 'app/authentication/models/invite-details.model';
import { UserTermsOverview } from 'app/authentication/models/user-terms-overview.model';
import { LoginDetails, PasswordResetDetails, RegisterDetails } from 'app/authentication/models';
import { LogoutOptions } from 'app/models/logout-options.model';
import { TemporaryPasswordReset } from 'app/authentication/models/temporary-password-reset.model';
import { User } from 'app/models/user.model';

// Enums
import { TermsType } from 'app/shared/enums/terms-type.enum';
import { RegistrationStatus } from 'app/authentication/enums/registration-status.enum';

export const SET_TOKEN = '[Auth] Set Token';

export const LOGIN = '[Auth] Login';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAIL = '[Auth] Login Fail';

export const SET_CLIENT = '[Auth] Set client';

export const LOGOUT = '[Auth] Logout';
export const LOGOUT_SUCCESS = '[Auth] Logout Success';
export const LOGOUT_FAIL = '[Auth] Logout Fail';

export const REHYDRATE_USER = '[Auth] Rehydrate user information';
export const REHYDRATE_USER_SUCCESS = '[Auth] Rehydrate user information success';
export const REHYDRATE_USER_FAIL = '[Auth] Rehydrate user information fail';

export const REFRESH_TOKEN = '[Auth] Refresh Token';
export const REFRESH_TOKEN_SUCCESS = '[Auth] Refresh Token Success';
export const REFRESH_TOKEN_FAIL = '[Auth] Refresh Token Fail';

export const REGISTER = '[Auth] Register';
export const REGISTER_SUCCESS = '[Auth] Register Success';
export const REGISTER_FAIL = '[Auth] Register Fail';

export const CLEAR_REGISTRATION_STATE = '[Auth] Clears the flag indicating a user has just registered';

export const GET_INVITE_DETAILS = '[Auth] Get invite details';
export const GET_INVITE_DETAILS_SUCCESS = '[Auth] Get invite details success';
export const GET_INVITE_DETAILS_FAIL = '[Auth] Get invite details fail';

export const ACCEPT_INVITE = '[Auth] Accept invite';
export const ACCEPT_INVITE_SUCCESS = '[Auth] Accept invite success';
export const ACCEPT_INVITE_FAIL = '[Auth] Accept invite fail';

export const CHECK_TERMS_SHUTOUT_REQUIRED = '[Auth] Check terms shut out required';
export const CHECK_TERMS_SHUTOUT_REQUIRED_SUCCESS = '[Auth] Check terms shut out required success';
export const CHECK_TERMS_SHUTOUT_REQUIRED_FAIL = '[Auth] Check terms shut out required fail';

export const GET_REGISTRATION_TERM = '[Auth] Get registration term';
export const GET_REGISTRATION_TERM_SUCCESS = '[Auth] Get registration term success';
export const GET_REGISTRATION_TERM_FAIL = '[Auth] Get registration term fail';

export const VALIDATE_REGISTRATION_CODE = '[Auth] Validate registration code';
export const VALIDATE_REGISTRATION_CODE_SUCCESS = '[Auth] Validate registration code success';
export const VALIDATE_REGISTRATION_CODE_FAIL = '[Auth] Validate registration code fail';

export const VALIDATE_EMAIL_ADDRESS = '[Auth] Validate email address';
export const VALIDATE_EMAIL_ADDRESS_SUCCESS = '[Auth] Validate email address success';
export const VALIDATE_EMAIL_ADDRESS_FAIL = '[Auth] Validate email address fail';

export const PASSWORD_RESET_REQUIRED = '[Auth] Password Reset Required';
export const CLEAR_PASSWORD_RESET_STATE = '[Auth] Clear Password Reset State';

export const SET_PASSWORD = '[Auth] Set Password';
export const SET_PASSWORD_SUCCESS = '[Auth] Set Password Success';
export const SET_PASSWORD_FAIL = '[Auth] Set Password Fail';

export class SetToken implements Action {
    readonly type = SET_TOKEN;
    constructor(public payload: TokenResponse) { }
}

export class Login implements Action {
    readonly type = LOGIN;
    constructor(public payload: LoginDetails) { }
}

export class LoginSuccess implements Action {
    readonly type = LOGIN_SUCCESS;
    constructor(public payload: User) { }
}

export class LoginFailed implements Action {
    readonly type = LOGIN_FAIL;
    constructor(public payload: string) { }
}

export class Logout implements Action {
    readonly type = LOGOUT;
    constructor(public payload: LogoutOptions) { }
}

export class LogoutSuccess implements Action {
    readonly type = LOGOUT_SUCCESS;
    constructor(public payload: LogoutOptions) { }
}

export class LogoutFailed implements Action {
    readonly type = LOGOUT_FAIL;
}

export class RefreshToken implements Action {
    readonly type = REFRESH_TOKEN;
    constructor(public payload: RefreshTokenRequest) { }
}

export class RefreshTokenSuccess implements Action {
    readonly type = REFRESH_TOKEN_SUCCESS;
    constructor(public payload: TokenResponse) { }
}

export class RefreshTokenFailed implements Action {
    readonly type = REFRESH_TOKEN_FAIL;
}

export class Register implements Action {
    readonly type = REGISTER;
    constructor(public payload: RegisterDetails) { }
}

export class RegisterSuccess implements Action {
    readonly type = REGISTER_SUCCESS;
    constructor(public payload: User) { }
}

export class ClearRegistrationState implements Action {
    readonly type = CLEAR_REGISTRATION_STATE;
}

export class RegisterFailed implements Action {
    readonly type = REGISTER_FAIL;
    constructor(public payload: string) { }
}

export class GetInviteDetails implements Action {
    readonly type = GET_INVITE_DETAILS;
    constructor(public payload: string) { }
}

export class GetInviteDetailsSuccess implements Action {
    readonly type = GET_INVITE_DETAILS_SUCCESS;
    constructor(public payload: InviteDetails) { }
}

export class GetInviteDetailsFail implements Action {
    readonly type = GET_INVITE_DETAILS_FAIL;
}

export class AcceptInvite implements Action {
    readonly type = ACCEPT_INVITE;
    constructor(public payload: string) { }
}

export class AcceptInviteSuccess implements Action {
    readonly type = ACCEPT_INVITE_SUCCESS;
}

export class AcceptInviteFail implements Action {
    readonly type = ACCEPT_INVITE_FAIL;
}

export class CheckTermsShutOutRequired implements Action {
    readonly type = CHECK_TERMS_SHUTOUT_REQUIRED;
}

export class CheckTermsShutOutRequiredSuccess implements Action {
    readonly type = CHECK_TERMS_SHUTOUT_REQUIRED_SUCCESS;
    constructor(public payload: UserTermsOverview) { }
}

export class CheckTermsShutOutRequiredFail implements Action {
    readonly type = CHECK_TERMS_SHUTOUT_REQUIRED_FAIL;
}

export class GetRegistrationTerm implements Action {
    readonly type = GET_REGISTRATION_TERM;
    constructor(public payload: TermsType) { }
}

export class GetRegistrationTermSuccess implements Action {
    readonly type = GET_REGISTRATION_TERM_SUCCESS;
    constructor(public payload: string) { }
}

export class GetRegistrationTermFail implements Action {
    readonly type = GET_REGISTRATION_TERM_FAIL;
}

export class ValidateRegistrationCode implements Action {
    readonly type = VALIDATE_REGISTRATION_CODE;
    constructor(public payload: string) { }
}

export class ValidateRegistrationCodeSuccess implements Action {
    readonly type = VALIDATE_REGISTRATION_CODE_SUCCESS;
    constructor(public payload: RegistrationStatus) { }
}

export class ValidateRegistrationCodeFail implements Action {
    readonly type = VALIDATE_REGISTRATION_CODE_FAIL;
}

export class ValidateEmailAddress implements Action {
    readonly type = VALIDATE_EMAIL_ADDRESS;
    constructor(public payload: string) { }
}

export class ValidateEmailAddressSuccess implements Action {
    readonly type = VALIDATE_EMAIL_ADDRESS_SUCCESS;
    constructor(public payload: boolean) { }
}

export class ValidateEmailAddressFail implements Action {
    readonly type = VALIDATE_EMAIL_ADDRESS_FAIL;
}

export class PasswordResetRequired implements Action {
    readonly type = PASSWORD_RESET_REQUIRED;
    constructor(public payload: TemporaryPasswordReset) { }
}

export class ClearPasswordResetState implements Action {
    readonly type = CLEAR_PASSWORD_RESET_STATE;
}

export class RehydrateUser implements Action {
    readonly type = REHYDRATE_USER;
}

export class RehydrateUserSuccess implements Action {
    readonly type = REHYDRATE_USER_SUCCESS;
    constructor(public payload: User) { }
}

export class RehydrateUserFail implements Action {
    readonly type = REHYDRATE_USER_FAIL;
}

export class SetPassword implements Action {
    readonly type = SET_PASSWORD;
    constructor(public payload: PasswordResetDetails) { }
}

export const SetPasswordSuccess = createAction(
    SET_PASSWORD_SUCCESS,
    props<{ payload: User}>()
);

export class SetPasswordFail implements Action {
    readonly type = SET_PASSWORD_FAIL;
}
export type All
    = SetToken
    | Login
    | LoginSuccess
    | LoginFailed
    | Logout
    | LogoutSuccess
    | LogoutFailed
    | RegisterSuccess
    | ClearRegistrationState
    | RefreshToken
    | RefreshTokenSuccess
    | RefreshTokenFailed
    | GetInviteDetails
    | GetInviteDetailsSuccess
    | GetInviteDetailsFail
    | AcceptInvite
    | AcceptInviteSuccess
    | AcceptInviteFail
    | CheckTermsShutOutRequired
    | CheckTermsShutOutRequiredSuccess
    | CheckTermsShutOutRequiredFail
    | GetRegistrationTerm
    | GetRegistrationTermSuccess
    | GetRegistrationTermFail
    | ValidateRegistrationCode
    | ValidateRegistrationCodeSuccess
    | ValidateRegistrationCodeFail
    | ValidateEmailAddress
    | ValidateEmailAddressSuccess
    | ValidateEmailAddressFail
    | PasswordResetRequired
    | ClearPasswordResetState
    | RehydrateUser
    | RehydrateUserSuccess
    | RehydrateUserFail
    | SetPassword
    | SetPasswordFail;