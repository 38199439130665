<div class="observation-container" *ngIf="(loaded$ | async) && pagedItems">

    <app-dialog-field *ngFor="let item of pagedItems"
                      [label]="item | itemLabel"
                      [description]="item | itemDescription"
                      [mandatory]="item.mandatory"
                      [showLabel]="!(item.type | isItemTypeRTW)"
                      [readonly]="item.readonly"
                      (fieldActive)="handleFieldActive($event)"
                      [selected]="item.id | isMatchingString:selectedItemId"
                      [type]="item.type"
                      [visible]="item.enabled">

        <div fxLayout="column" fxLayoutGap="4px">
            <div *ngIf="!(item.type | isItemType:ItemType.MultiOptionList | isItemType:ItemType.ImageList)" fxLayoutGap="4px" fxLayoutGap.gt-xs="32px">
                <app-observation *ngFor="let itemObservation of item.itemObservations"
                                 (valueUpdated)="onValueUpdated($event)"
                                 (selectedChanged)="onSelectedChanged($event)"
                                 [item]="item"
                                 [itemObservation]="itemObservation"
                                 [selected]="itemObservation.itemId | isMatchingString:selectedItemId"
                                 (deleteObservationClicked)="deleteObservation($event)"
                                 (formStatusChanged)="onFormStatusChanged($event)"></app-observation>
            </div>
            <div *ngIf="item.type | isItemType:ItemType.MultiOptionList" fxLayoutGap="4px" fxLayoutGap.gt-xs="32px">
                <app-observation-multi-option-list [item]="item"
                                                   (observationRemoved)="deleteObservation($event)"
                                                   (observationAdded)="onMultiListValueAdded($event)">
                </app-observation-multi-option-list>
            </div>
            <div *ngIf="item.type | isItemType:ItemType.ImageList" fxLayoutGap="4px" fxLayoutGap.gt-xs="32px">
                <app-observation-image-list [item]="item"
                                            [ownerId]="ownerId"
                                            (observationRemoved)="deleteObservation($event)"
                                            (observationAdded)="onImageListValueAdded($event)">
                </app-observation-image-list>
            </div>
            <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="start" *ngIf="item.type | isItemTypeList">
                <button class="btn large primary dark add-button" (click)="addObservation(item)"><mat-icon>add</mat-icon>Add additional item</button>
            </div>
        </div>
    </app-dialog-field>
</div>