<div fxFlex="1 1 auto" class="dialog-container text {{ position }} {{colorClass}}"
     [ngStyle.gt-sm]="{ 'max-width': '100%', 'max-height': '100%' }"
     [ngStyle.lt-sm]="{ 'max-width': '100%', 'max-height': '100%', 'width': '100%', 'height': '100%' }"
     [ngClass]="{ 'center-align': centerAlign }">
    <div fxLayout="column"  class="dialog-header" [class.padding-bottom-none]="noSubHeaderPadding">
        <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
            <i *ngIf="dialogTitleIcon" class="fa primary-text icon-size-l" [ngClass]="dialogTitleIcon"></i>
            <div fxFlex="1 1 0%" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="space-between"
                 *ngIf="dialogTitle || dialogSubTitle">
                <h3 fxFlex class="dialog-header-title">
                    {{dialogTitle ? dialogTitle : ' '}}
                </h3>
                <p *ngIf="dialogSubTitle" class="text-gray-8 font-size-s margin-bottom-0">{{ dialogSubTitle }}</p>
            </div>
            <ng-content select="[header-content]" class="full-width"></ng-content>
            <div fxFlex *ngIf="!dialogTitle && !dialogSubTitle && showClose">&nbsp;</div>
            <button *ngIf="handleCloseEvent || showClose"
                    class="btn medium icon text-white background-gray-8 close-button" (click)="onCloseClick()">
                <i class="fa fa-solid fa-times" aria-label="close dialog"></i>
            </button>
        </div>
        <ng-content select="[sub-header]"></ng-content>
    </div>

    <div fxFlex="1 1 auto" fxLayout="column" class="dialog-content"
         [ngClass]="{ 'no-footer': !showFooterActions, 'padding-none': noBodyPadding }"
         (scroll)="onContentScroll($event)">
        <ng-content select="[body]"></ng-content>
    </div>

    <div fxFlex="nogrow" *ngIf="showFooterActions" fxLayoutAlign="end center" fxLayoutGap="10px"
         class="button-container">
        <ng-content select="[footer-actions]" fxLayoutAlign="end center" fxLayoutGap="10px"></ng-content>
    </div>
</div>