import { Action } from '@ngrx/store';
import { DisplayScreen, MobileDevice } from 'app/shared/enums';
import { AssessmentSetGroup } from 'app/shared/models/assessment-set-group.model';
import { ModuleDetails } from 'app/models/module-details.model';

export const SET_DISPLAY_SCREEN_TYPE = '[Global UI] Set Display Type (Desktop, Tablet, Mobile)';
export const SET_MOBILE_DEVICE_TYPE = '[Global UI] Set Mobile Device Type (iOS, Android, Unknown)';
export const SET_APPLICATION = '[Global UI] Set the current application';
export const SET_MODULE = '[Global UI] Set the current module';
export const SET_ASSESSMENT_SET_SHOWN = '[Global UI] Set the state of assessment set dialog shown';
export const EMPTY_STATE = '[Global UI] Resets the state of the UI store';
export const SET_USER_LOGGED_IN_FIRST_TIME = '[Global UI] Sets the state to show the current user has logged in for the first time';
export const SET_USER_SEEN_INTRO = '[Global UI] Sets the state to show the current user has seen first time login message';
export const SET_RTW_SET_SHOWN = '[Global UI] Set the state of the RTW dialog to have shown';
export const EMPTY_RTW_SHOWN = '[Global UI] Set the state of the RTW dialog to have not shown';
export const SET_CURRENT_BADGE_ID = '[Global UI] Set the current badge id';
export const GET_CURRENT_BADGE_ID = '[Global UI] Get the current badge id';

export const GET_ASSESSMENT_SET_GROUPS_LIST = '[Set Templates] Get Assessment Set Groups List';
export const GET_ASSESSMENT_SET_GROUPS_LIST_SUCCESS = '[Set Templates] Get Assessment Set Groups List Success';
export const GET_ASSESSMENT_SET_GROUPS_LIST_FAIL = '[Set Templates] Get Assessment Set Groups List Fail';

export const START_SESSION_TIMEOUT = '[Global UI] Start session timeout';
export const CLEAR_SESSION_TIMEOUT = '[Global UI] Clear session timeout';

export class SetDisplayScreenType implements Action {
    readonly type = SET_DISPLAY_SCREEN_TYPE;
    constructor(public payload: DisplayScreen) { }
}

export class SetMobileDeviceType implements Action {
    readonly type = SET_MOBILE_DEVICE_TYPE;
    constructor(public payload: MobileDevice) { }
}

export class SetApplication implements Action {
    readonly type = SET_APPLICATION;
    constructor(public payload: ModuleDetails) { }
}

export class SetAssessmentDialogShown implements Action {
    readonly type = SET_ASSESSMENT_SET_SHOWN;
}

export class EmptyState implements Action {
    readonly type = EMPTY_STATE;
}

export class SetRTWShown implements Action {
    readonly type = SET_RTW_SET_SHOWN;
}

export class UserLoggedInFirstTime implements Action {
    readonly type = SET_USER_LOGGED_IN_FIRST_TIME;
}

export class UserSeenIntro implements Action {
    readonly type = SET_USER_SEEN_INTRO;
}

export class EmptyRTW implements Action {
    readonly type = EMPTY_RTW_SHOWN;
}

export class SetCurrentBadgeId implements Action {
    readonly type = SET_CURRENT_BADGE_ID;
    constructor(public payload: string) { }
}

export class GetCurrentBadgeId implements Action {
    readonly type = GET_CURRENT_BADGE_ID;
}

export class GetAssessmentSetsGroupsList implements Action {
    readonly type = GET_ASSESSMENT_SET_GROUPS_LIST;
}

export class GetAssessmentSetsGroupsListSuccess implements Action {
    readonly type = GET_ASSESSMENT_SET_GROUPS_LIST_SUCCESS;
    constructor(public payload: AssessmentSetGroup[]) { }
}

export class GetAssessmentSetsGroupsListFail implements Action {
    readonly type = GET_ASSESSMENT_SET_GROUPS_LIST_FAIL;
}

export class StartSessionTimeout implements Action {
    readonly type = START_SESSION_TIMEOUT;
}

export class ClearSessionTimeout implements Action {
    readonly type = CLEAR_SESSION_TIMEOUT;
    constructor(public closeSessionTimeoutDialog: boolean = true) { }
}

export type AllUserInterfaceActions
    = SetDisplayScreenType
    | SetMobileDeviceType
    | SetApplication
    | SetAssessmentDialogShown
    | EmptyState
    | UserLoggedInFirstTime
    | UserSeenIntro
    | SetRTWShown
    | EmptyRTW
    | SetCurrentBadgeId
    | GetCurrentBadgeId
    | GetAssessmentSetsGroupsList
    | GetAssessmentSetsGroupsListSuccess
    | GetAssessmentSetsGroupsListFail
    | StartSessionTimeout
    | ClearSessionTimeout;