// angular
import { Component, EventEmitter, Output, Input, OnInit, ViewEncapsulation, ViewChildren, QueryList, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

// ngrx | rxjs
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// store
import * as fromStore from 'app/connect/store';

// models
import { Filter } from 'app/models/filter/filter.model';
import { FilterConfiguration } from 'app/models/filter/filter-configuration.model';
import { FilterOption } from 'app/models/filter/filter-option.model';
import { FilterOptionValue } from 'app/models/filter/filter-option-value.model';
import { FolderSummary } from 'app/connect/models/folder-summary.model';

// utilities
import { showHideAnimation } from 'app/shared/utilities/animation-utilities';
import { toDatefromIsoDatePartString, toDateOnlyStripTimezone } from 'app/shared/utilities/date-utilities';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: showHideAnimation
})
export class FilterComponent extends BaseComponent implements OnInit, OnChanges {

    @ViewChild('folderContainer') folderContainer: ElementRef;

    filter: Filter;

    menuState: string;
    flattenedSelectedValues: FilterOptionValue[];
    selectedValues = new Map<string, FilterOptionValue[]>();
    initialised = false;
    fromDate: string;
    toDate: string;
    searchTextKey = 's';
    folderLeft = 0;
    folderWidth = 194;
    searchText: string;

    @ViewChildren('optionDropdown')
    dropDowns: QueryList<MatSelect>;

    @Input()
    configuration: FilterConfiguration;

    @Input()
    visibleRecordCount: number | undefined;

    @Output()
    search = new EventEmitter<Filter>();

    @Output()
    openFolderClicked = new EventEmitter<FolderSummary>();

    @Output()
    deleteFolderClicked = new EventEmitter<FolderSummary>();

    @Output()
    editFolderClicked = new EventEmitter<FolderSummary>();

    filterVisible = false;

    dateOptionControls: FormControl[];
    options: FilterOption[];

    constructor(
        private store: Store<fromStore.ConnectStoreState>,
        private route: ActivatedRoute,
        private router: Router,
        private actionsSubject: ActionsSubject) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.configuration && changes?.configuration) {
            this.filtersUpdated();
        }
    }

    ngOnInit(): void {
        this.actionsSubject.pipe(
            ofType(fromStore.ExportClicked),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.store.dispatch(fromStore.ExportWithFilter({filter: this.filter}));
            });

        if (this.configuration) {
            this.filtersUpdated();
        }
    }

    private filtersUpdated(): void {
        this.filter = new Filter(this.configuration);

        const params = this.route.snapshot.queryParams;

        this.filter.searchText = params.s;

        if (this.filter && this.filter.configuration?.options) {
            for (const option of this.filter.configuration.options) {
                const valuesParam = <string>params[option.name];

                if (!valuesParam) {
                    continue;
                }

                if (option.multiSelect) {
                    const values = valuesParam.split(',');
                    for (const value of values) {
                        this.filter.setFilterOptionId(option.name, value);
                    }

                    this.selectedValues[option.name] = values.map(v => option.values.find(ov => ov.id === v));
                } else {
                    this.filter.setFilterOptionId(option.name, valuesParam);
                    this.selectedValues[option.name] = [option.values.find(ov => ov.id === valuesParam)];
                }
            }
        }

        this.options = this.filter.configuration.options.filter(o => o.values && o.values.length > 0);

        this.dateOptionControls = [];
        let isFirstDate = true;
        for (const option of this.filter.configuration.dateOptions) {

            const valuesParam = params[option.name];

            if (!valuesParam) {
                this.dateOptionControls.push(new FormControl(null));
                continue;
            }
            this.dateOptionControls.push(new FormControl(toDatefromIsoDatePartString(valuesParam)));
            this.filter.setFilterOptionDateValue(option.name, valuesParam);

            if (valuesParam) {
                if (isFirstDate) {
                    this.fromDate = `${option.name} ${valuesParam}`;
                } else {
                    this.toDate = `${option.name} ${valuesParam}`;
                }
            }

            isFirstDate = false;
        }

        this.applyFilters();
    }

    onOpenFolderClicked(folder: FolderSummary): void {
        this.openFolderClicked.emit(folder);
    }

    onDeleteFolderClicked(folder: FolderSummary): void {
        this.deleteFolderClicked.emit(folder);
    }

    onEditFolderClicked(folder: FolderSummary): void {
        this.editFolderClicked.emit(folder);
    }

    onScrollRightClicked(): void {
        const totalWidth =  this.folderWidth * this.configuration.folders.length;
        const containerWidth = this.folderContainer.nativeElement.clientWidth;
        const offset = totalWidth + this.folderLeft;
        if (offset > containerWidth) {
            this.folderLeft -= this.folderWidth;
        }
    }

    onScrollLeftClicked(): void {
        this.folderLeft += this.folderWidth;
        if (this.folderLeft > 0) {
            this.folderLeft = 0;
        }
    }

    applyFilters(): void {
        this.addParameter(this.searchTextKey, this.filter.searchText);
        this.setFilterDateOptions();
        this.setFilterOptions();

        setTimeout(() => {
            this.store.dispatch(fromStore.SearchClicked({filter: this.filter}));
        });
    }

    clearFilters(): void {
        this.filter.clear();
        this.addParameter(this.searchTextKey, null);

        for (const option of this.configuration.options) {
            this.selectedValues[option.name] = [];
            this.addParameter(option.name, null);
        }

        for (let i = 0; i < this.dateOptionControls.length; i++) {
            this.dateOptionControls[i].setValue(null);
            this.configuration.dateOptions[i].clear();
            this.addParameter(this.configuration.dateOptions[i].name, null);
        }

        this.fromDate = null;
        this.toDate = null;

        this.applyFilters();
    }

    onSearchTextUpdated(search: string): void {
        this.filter.searchText = search;
    }

    toggleValueSelected(option: FilterOption, value: FilterOptionValue): void {
        if (this.selectedValues[option.name] && this.selectedValues[option.name].find(x => x.optionName === option.name && x.id === value.id)) {
            this.selectedValues[option.name] = this.selectedValues[option.name].filter(x => x.id !== value.id);
        } else {
            if (!option.multiSelect) {
                this.selectedValues[option.name] = [value];
            } else {
                if (!this.selectedValues[option.name]) {
                    this.selectedValues[option.name] = [];
                }

                this.selectedValues[option.name] = [...this.selectedValues[option.name], value];
            }
        }
    }

    handleMultiSelectChange(option: FilterOption, change: MatSelectChange) {
        this.selectedValues[option.name] = change.value;
    }

    isChecked(option: FilterOption, value: FilterOptionValue): boolean {
        if (!value) {
            return false;
        }

        return this.selectedValues &&
            this.selectedValues[option.name] &&
            this.selectedValues[option.name].find(v => v.id === value.id);
    }

    removeSearchText(): void {
        this.filter.searchText = undefined;
        this.setQueryString(this.searchTextKey, null);
        this.applyFilters();
    }

    removeFilter(value: FilterOptionValue): void {
        const option = this.configuration.options.find(o => o.values.find(v => v.optionName === value.optionName && v.id === value.id));
        this.selectedValues[option.name] = this.selectedValues[option.name].filter(v => v.id !== value.id);
        this.setQueryString(option.name, null);
        this.applyFilters();
    }

    removeFromDate(): void {
        this.dateOptionControls[0].setValue(null);
        this.fromDate = null;
        this.applyFilters();
    }

    removeToDate(): void {
        this.dateOptionControls[1].setValue(null);
        this.toDate = null;
        this.applyFilters();
    }

    private addParameter(name: string, value: string): void {
        if (name && this.configuration && !this.configuration.isDialog) {
            const filterOption = this.configuration.getOption(name);

            if (!filterOption) {
                this.setQueryString(name, value);

                return;
            }

            const selectedIds = this.filter.getFilterOption(name).selectedIds;

            if (selectedIds && selectedIds.length) {
                value = '';

                for (const optionValue of selectedIds) {
                    value = `${value}${optionValue},`;
                }

                value = value.slice(0, -1);
            }

            this.setQueryString(name, value);
        }
    }

    private setQueryString(name: string, value: string): void {
        setTimeout(() =>
            this.router.navigate([], {
                queryParams: {
                    [name]: value
                },
                queryParamsHandling: 'merge'
            }), 10);
    }

    private setFilterDateOptions(): void {
        for (let i = 0; i < this.dateOptionControls.length; i++) {
            const dateValue = this.dateOptionControls[i].value;
            const option = this.filter.configuration.dateOptions[i];
            this.addParameter(option.name, null);

            if (dateValue) {

                option.setValue(toDateOnlyStripTimezone(dateValue));
                this.addParameter(option.name, option.stringValue);

                if (i === 0) {
                    this.fromDate = `${option.name} ${option.stringValue}`;
                } else {
                    this.toDate = `${option.name} ${option.stringValue}`;
                }
            } else {
                option.setValue(null);
            }
        }
    }

    private setFilterOptions(): void {
        this.flattenedSelectedValues = [];

        this.filter.clearFilterOptionIds();
        for (const optionName of Object.keys(this.selectedValues)) {
            this.addParameter(optionName, null);
            for(const value of this.selectedValues[optionName]) {
                if (value) {
                    this.filter.getFilterOption(optionName).selectValue(value.id);
                    this.addParameter(optionName, value.id);
                }
            }

            this.flattenedSelectedValues = [...this.flattenedSelectedValues, ...this.selectedValues[optionName]];
        }
    }
}