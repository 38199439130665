import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// RXJS / NGRX
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

// Store
import * as fromDocuments from 'app/portal/modules/document-management/store';

// Services
import { BlobService } from 'app/shared/services/blob.service';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Models
import { GeneratePdfPreviewRequest } from 'app/portal/modules/document-management/models/generate-pdf-preview-request.model';
import { TemplatePreview } from 'app/portal/modules/document-management/models/template-preview.model';

// Enums
import { DocumentTemplateType } from 'app/portal/modules/document-management/enums/document-template-type.enum';
import { ContentType } from 'app/shared/enums/content-type.enum';

@Component({
    selector: 'app-template-preview',
    styleUrls: ['./template-preview-dialog.component.scss'],
    templateUrl: './template-preview-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})

export class TemplatePreviewDialogComponent extends BaseComponent implements OnInit {
    markup: string;
    loadingPdf: boolean = false;
    blobData: Blob;
    documentTemplateType: DocumentTemplateType;
    readonly pdfContentType = ContentType.Pdf;

    constructor(
        private store: Store<fromDocuments.DocumentsState>,
        @Inject(MAT_DIALOG_DATA) data: TemplatePreview) {
            super();
            this.markup = data.markup;
            this.documentTemplateType = data.documentTemplateType;

            if (this.documentTemplateType === DocumentTemplateType.PDF) {
                this.blobData = data.blobData;
            }
        }

    ngOnInit(): void {
        this.store.pipe(
            select(fromDocuments.getLoading),
            takeUntil(this.ngUnsubscribe))
            .subscribe(loading => {
                if (loading) {
                    this.loadingPdf = loading;
                }
            });

        this.store.pipe(
            select(fromDocuments.getPreviewDocument),
            takeUntil(this.ngUnsubscribe))
            .subscribe(document => {
                if (document) {
                    this.blobData = BlobService.base64toBlob(document, this.pdfContentType);
                    this.loadingPdf = false;
                }
            });

        if (this.documentTemplateType !== DocumentTemplateType.PDF) {
            this.store.dispatch(new fromDocuments.GeneratePdf(new GeneratePdfPreviewRequest(this.markup)));
        }
    }
}