<app-dialog [dialogTitle]="title" defaultWidth="80vw" defaultHeight="80vw" id="document-dialog">

    <div body fxFlex="100">
        <div class="container">
            <div class="inner-container">
                <iframe *ngIf="isSignatory && documentLink" id="document-iframe" class="theme-border-1px" [src]="documentLink | safe: 'resourceUrl'"></iframe>
                <ng2-pdfjs-viewer *ngIf="!isSignatory && pdf" [pdfSrc]="pdf" id="document-iframe" class="theme-border-1px"></ng2-pdfjs-viewer>
            </div>
        </div>
    </div>

    <div footer-actions>
        <button class="btn large primary light" mat-dialog-close>Close</button>
    </div>

</app-dialog>