<div>
    <div>
        <div id="toolbar-container" class="inset-container">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="margin-bottom-16">
                <a *ngIf="backRouterLink" [routerLink]="backRouterLink" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="pointer">
                    <i class="fa fa-regular fa-chevron-left"></i>
                    <div>Back</div>
                </a>
                <div fxFlex>
                    <ng-container *ngTemplateOutlet="filter"></ng-container>
                </div>
                <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px" class="buttons-container">
                    <ng-container *ngTemplateOutlet="standAloneButtonsContent"></ng-container>

                    <a *ngIf="showShowMoreButton$ | async" class="btn large primary light" (click)="onShowMoreButtonClick()">More Info</a>
                    
                    <a *ngIf="showNewButton$ | async" class="btn large primary dark icon-l" (click)="onNewButtonClick()">
                        <i class="fa fa-plus"></i>
                        {{newButtonCaption$ | async}}
                    </a>
                    <a *ngIf="showAdditionalOptions || (allowSwitchView$ | async) || (allowExport$ | async)" [matMenuTriggerFor]="additionalMenu" class="btn large primary light icon">
                        <i class="fa fa-gear"></i>
                    </a>
                    <app-maximizer target="page"></app-maximizer>
                </div>
            </div>
            
        </div>
    </div>
    <div class="connect-banner" *ngIf="hasCustomBannerContent">
        <div class="background-color custom-banner-content">
            <div class="inset-container">
                <ng-content select="[banner-content]"></ng-content>
            </div>

            <nav *ngIf="customMenus?.length > 0" class="default">
                <div class="inset-container" fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                        <a *ngFor="let menu of customMenus" [class.active]="menu.id === currentMenu.id" (click)="onCustomMenuClick(menu)">{{menu.label}}</a>
                    </div>
                    <div>
                        <ng-content select="[nav-content]"></ng-content>
                    </div>
                </div>
            </nav>
        </div>
    </div>

</div>

<ng-template #additionalOptionsMatMenuContent>
    <ng-content></ng-content>
</ng-template>
<ng-template #standAloneButtonsContent>
    <ng-content select="[standalone-buttons]"></ng-content>
</ng-template>
<ng-template #filter>
    <ng-content select="[filter]"></ng-content>
</ng-template>

<mat-menu #additionalMenu="matMenu">
    <div id="toolbar-popup-menu">
        <ng-container *ngTemplateOutlet="additionalOptionsMatMenuContent"></ng-container>
        <button mat-menu-item *ngIf="allowSwitchView$ | async" (click)="handleSwitchViewClick(ViewType.Card)" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <span>Card View</span>
            <mat-icon *ngIf="currentViewType === ViewType.Card" class="rag-green-text">check</mat-icon>
        </button>
        <button mat-menu-item *ngIf="allowSwitchView$ | async" (click)="handleSwitchViewClick(ViewType.List)" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <span>Table View</span>
            <mat-icon *ngIf="currentViewType === ViewType.List" class="rag-green-text">check</mat-icon>
        </button>
        <button mat-menu-item *ngIf="allowExport$ | async" (click)="handleExportClick()">
            Export CSV
        </button>
    </div>
</mat-menu>