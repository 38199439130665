import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// ngrx | rxjs
import { takeUntil } from 'rxjs/operators';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// services
import { AddressLookupService } from 'app/portal/modules/inputs/services/address-lookup.service';

// models
import { Address } from 'app/portal/modules/inputs/models/address.model';

// extensions
import { isNullOrWhitespace } from 'app/shared/utilities/string-utilities';

@Component({
    selector: 'app-address-input',
    templateUrl: './address-input.component.html'
})
export class AddressInputComponent extends BaseComponent implements OnInit, OnChanges {
    public hasAddresses = false;
    public showAddress = false;
    public addresses: Address[];
    public formattedAddresses: string[];
    public addressForm: FormGroup;
    public postcodeForm: FormGroup;
    public lookupError = '';

    @Input()
    addressJson: string;

    @Input()
    address: Address;

    @Input()
    disabled = false;

    @Output()
    addressChanged: EventEmitter<Address> = new EventEmitter();

    constructor(
        private addressLookupService: AddressLookupService,
        private formBuilder: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        if (!this.postcodeForm) {
            this.postcodeForm = this.formBuilder.group({
                postcode: [null, [
                    Validators.required,
                    Validators.pattern(`^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2}))$`)
                ]]
            });
        }

        if (!this.addressForm) {
            this.clearAddress();
        }

        if (this.disabled) {
            this.addressForm.disable({emitEvent: false});
            this.postcodeForm.disable({emitEvent: false});
        }

        if (!isNullOrWhitespace(this.addressJson)) {
            this.address = JSON.parse(this.addressJson);
        }

        if (!isNullOrWhitespace(this.address?.postcode)) {
            this.addressForm.patchValue({...this.address});
            this.showAddress = true;
        }

        this.addressForm.valueChanges.pipe(
            takeUntil(this.ngUnsubscribe)).subscribe(
            () => {
                this.emitAddressValue();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.disabled) {
            if (this.disabled) {
                this.addressForm?.disable({emitEvent: false});
                this.postcodeForm?.disable({emitEvent: false});
            } else {
                this.addressForm?.enable({emitEvent: false});
                this.postcodeForm?.enable({emitEvent: false});
            }
        }

        if (changes?.address && this.addressForm) {
            this.postcodeForm = this.formBuilder.group({
                postcode: [this.address?.postcode ?? '', [
                    Validators.required,
                    Validators.pattern(`^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2}))$`)
                ]]
            });

            this.addressForm = this.formBuilder.group({
                addressLine1: [this.address?.addressLine1 ?? '', [Validators.required]],
                addressLine2: [this.address?.addressLine2 ?? ''],
                town: [this.address?.town ?? '', [Validators.required]],
                county: [this.address?.county ?? ''],
                postcode: [this.address?.postcode ?? '', [
                    Validators.required,
                    Validators.pattern(`^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2}))$`)
                ]]
            });

            this.addressForm.valueChanges.pipe(
                takeUntil(this.ngUnsubscribe)).subscribe(
                () => {
                    this.emitAddressValue();
                });

            this.showAddress = !!(this.address?.postcode);
            this.hasAddresses = false;
            this.addresses = undefined;

            const postcodeSearch = this.postcodeForm.controls.postcode;
            postcodeSearch.markAsPristine();
            postcodeSearch.markAsUntouched();
        }
    }

    onSearchClicked(): void {
        const postcode = this.postcodeForm.getRawValue().postcode;
        this.addressLookupService.postCodeLookup(postcode).subscribe(
            addressResult => {
                if (addressResult.isSuccess) {
                    if (addressResult.addresses.length === 1) {
                        this.addressForm.patchValue({...addressResult.addresses[0]});
                        this.showAddress = true;
                        this.hasAddresses = false;
                    } else {
                        this.hasAddresses = true;
                        this.addresses = addressResult.addresses;
                    }
                    this.lookupError = '';
                } else {
                    this.lookupError = addressResult.errorMessage;
                }
            });
    }

    formatAddress(address: Address): string {
        let formattedAddress = address.addressLine1;

        if (!isNullOrWhitespace(address.addressLine2)) {
            formattedAddress = formattedAddress + ', ' + address.addressLine2;
        }

        if (!isNullOrWhitespace(address.town)) {
            formattedAddress = formattedAddress + ', ' + address.town;
        }

        if (!isNullOrWhitespace(address.county)) {
            formattedAddress = formattedAddress + ', ' + address.county;
        }

        formattedAddress = formattedAddress + ', ' + address.postcode;

        return formattedAddress;
    }

    toggleDataEntry(isManual: boolean) {
        this.showAddress = isManual;
        this.formattedAddresses = [];
        this.hasAddresses = false;
    }

    updateAddress(address: Address): void {
        this.addressForm.patchValue({...address});
        this.showAddress = true;
        this.hasAddresses = false;
    }

    clearAddress() {
        const initialised = !!this.addressForm;
        this.addressForm = this.formBuilder.group({
            addressLine1: ['', [Validators.required]],
            addressLine2: [''],
            town: ['', [Validators.required]],
            county: [''],
            postcode: ['', [
                Validators.required,
                Validators.pattern(`^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2}))$`)
            ]]
        });

        this.addressForm.valueChanges.pipe(
            takeUntil(this.ngUnsubscribe)).subscribe(
            () => {
                this.emitAddressValue();
            });

        if (initialised) {
            this.emitAddressValue();
        }
    }

    private emitAddressValue() {
        this.address = this.addressForm.getRawValue();
        this.address.isValid = this.addressForm.valid || this.addressForm.disabled;

        if (this.hasAddress()) {
            this.addressChanged.emit(this.address);
        } else {
            this.addressChanged.emit(null);
        }
    }

    hasAddress(): boolean {
        return !(isNullOrWhitespace(this.address?.addressLine1) &&
            isNullOrWhitespace(this.address?.addressLine2) &&
            isNullOrWhitespace(this.address?.town) &&
            isNullOrWhitespace(this.address?.county) &&
            isNullOrWhitespace(this.address?.postcode));
    }
}
