export function toTitleCase(value: string): string {

    if (!value) {
        return '';
    }

    return value.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export function toAlphaNumericUnderline(value: string): string {
    return value.replace(/[^a-zA-Z\d\s:]/, '').replace(/\s/, '_');
}

export function removeSpaces(value: string): string {
    if (!value || value === '') {
        return value;
    }

    return value.replace(/\s/, '');
}

export function getFileExtension(filename: string): string {
    const index = filename.lastIndexOf('.');

    if (index < 0) {
        return '';
    }

    const ext = filename.substring(index + 1);

    if (!ext) {
        return '';
    }

    return ext.toLowerCase();
}

export function newId(): string {
    return `${getRandomHex(8)}-${getRandomHex(4)}-${getRandomHex(4)}-${getRandomHex(4)}-${getRandomHex(12)}`;
}

export function getDataFromBase64File(base64File: string): string {
    return base64File.slice(base64File.indexOf('base64,') + 7);
}

export function getBase64Icon(type: string, base64Data: string): string {
    return `data:${type};base64,${base64Data}`;
}

function randomCharacters(sourceCharacters: string, count: number) {

    let result = '';

    while (result.length < count) {
        result += sourceCharacters[Math.floor(Math.random() * Math.floor(sourceCharacters.length))];
    }

    return result;
}

function getRandomHex(count: number): string {
    return randomCharacters('0123456789abcdef', count);
}

export function isNullOrWhitespace(value: string): boolean {
    return !value || value.length === 0 || removeSpaces(value).length === 0;
}

export function isNullOrEmpty(value: string): boolean {
    return !value || value.length === 0;
}

export function getBase64Data(base64File: string, type: string): string {
    return `data:${type};base64,${base64File}`;
}

export function valueToString(value: any, nullValue: string = null): string {
    return value === undefined || value === null || value === '' ? nullValue : `${value}`;
}

export function isNullOrWhiteSpace(input: string): boolean {
    return (input || '').trim() === '';
}

export function padLeft(text: string, desiredLength: number, paddingText: string): string {
    text ||= '';
    const paddingLength = Math.max(0, desiredLength - text.length);
    if (paddingLength < 1) {
        return text;
    }

    const padding = paddingText.repeat(Math.ceil(paddingLength / paddingText.length)).substr(0, paddingLength);

    return padding + text;
}

export function padRight(text: string, desiredLength: number, paddingText: string): string {
    text ||= '';
    const paddingLength = Math.max(0, desiredLength - text.length);
    if (paddingLength < 1) {
        return text;
    }

    const padding = paddingText.repeat(Math.ceil(paddingLength / paddingText.length)).substr(0, paddingLength);

    return text + padding;
}

export function nullIfEmptyOrWhiteSpace(input: string): string {
    return isNullOrWhiteSpace(input) ? null : input;
}