<app-authentication-layout id="sign-in" fxFlex>
    <app-brand-panel title="Sign in" fxFlex [showCloseButton]="false">
        <div [formGroup]="form" class="sign-in-form" fxLayout="column" fxLayoutGap="4px" fxLayoutGap.gt-xs="32px" (keydown.enter)="loginUser()">
            
            <div fxLayout="column" fxLayoutGap="16px">
                <div>
                    <div class="font-question-label">Email</div>
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="Enter your email address" formControlName="email" autocomplete="off" type="text">
                        <mat-error *ngIf="form.get('email').hasError('required')">Email is required</mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <div class="font-question-label">Password</div>
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="Enter your password" formControlName="password" autocomplete="off" [type]="hidePassword ? 'password' : 'text'">
                        <mat-icon matSuffix class="pointer" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' :
                            'visibility'}}</mat-icon>
                        <mat-error *ngIf="form.get('password').hasError('required')">Password is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="mat-error" *ngIf="authenticationMessage">
                    {{authenticationMessage}}
                </div>
                <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout="column-reverse" fxLayoutGap="16px" >
                    <div class="link" [routerLink]="['/auth/forgot-password']"
                        (click)="authenticationEventTrackingService.forgotPasswordClicked()">
                        <div>Forgot your password?</div>
                    </div>
                    <button
                        class="btn large primary dark"
                        [disabled]="working"
                        (click)="loginUser()">
                        Sign in
                    </button>
                </div>
            </div>
        </div>
    </app-brand-panel>
    <app-client-selector *ngIf="loggedIn" [logoutOnBackClick]="true" backLabel="Back to login" [float]="false"></app-client-selector>
</app-authentication-layout>
