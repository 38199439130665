<div id="page" fxLayout="column" [fxLayoutGap]="layoutSpacing" fxFlex="100">
    <div fxLayout="column" class="toolbar-filter-container" [class.margin-bottom-0]="filterConfiguration">
        <app-toolbar [showAdditionalOptions]="showAdditionalOptions" [hasCustomBannerContent]="hasCustomBannerContent" [backRouterLink]="backRouterLink">
            <ng-content select="[toolbar]"></ng-content>
            <ng-content standalone-buttons select="[standalone-buttons]"></ng-content>
            <ng-content banner-content select="[banner-content]"></ng-content>
            <ng-content nav-content select="[nav-content]"></ng-content>
            <app-filter
                filter
                [hidden]="!filterConfiguration"
                [configuration]="filterConfiguration"
                [visibleRecordCount]="visibleRecordCount"
                (openFolderClicked)="onOpenFolderClicked($event)"
                (deleteFolderClicked)="onDeleteFolderClicked($event)"
                (editFolderClicked)="onEditFolderClicked($event)">
                <ng-content standalone-filter-buttons select="[standalone-filter-buttons]"></ng-content>
            </app-filter>
        </app-toolbar>
    </div>
    
    <div class="page-content inset-container" fxFlex="fill" [ngClass]="{'hide-scroll': hideScroll}">
        <ng-content select="[content]"></ng-content>
    </div>
</div>