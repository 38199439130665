<div class="observation-item" [attr.data-name]="item.name" [ngClass]="{'selected': selected}" *ngIf="item.enabled">

    <div class="observation-item-content">
        <!-- The score item-->
        <!-- <app-observation-score [score]="itemObservation.score"></app-observation-score> -->

        <div class="observation">
            <div *ngIf="!item.readonly" fxFlex fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="10px">

                <div fxFlex>
                    <!-- TextOnly = 3 -->
                    <app-observation-text-only *ngIf="item.type | isItemType:ItemType.TextOnly" [form]="form"
                                               (selectedChanged)="onSelectedChanged($event)" [item]="item" [itemObservation]="itemObservation"
                                               (valueUpdated)="onValueUpdated($event)"></app-observation-text-only>

                    <!-- Postcode = 24 -->
                    <app-observation-postcode *ngIf="item.type | isItemType:ItemType.Postcode" [form]="form"
                                              (selectedChanged)="onSelectedChanged($event)" [item]="item" [itemObservation]="itemObservation"
                                              (valueUpdated)="onValueUpdated($event)"></app-observation-postcode>

                    <!-- NetPromoterScore = 5 -->
                    <app-observation-net-promoter-score *ngIf="item.type | isItemType:ItemType.NetPromoterScore" [form]="form" [item]="item"
                                                        [itemObservation]="itemObservation" (valueUpdated)="onValueUpdated($event)">
                    </app-observation-net-promoter-score>

                    <!-- Date = 8 -->
                    <app-observation-date *ngIf="isDate" [form]="form" (selectedChanged)="onSelectedChanged($event)"
                                          [item]="item" [itemObservation]="itemObservation" (valueUpdated)="onValueUpdated($event)">
                    </app-observation-date>

                    <!-- Document = 9 or Document List = 17 -->
                    <app-observation-document *ngIf="isDocument" [form]="form"
                                              (selectedChanged)="onSelectedChanged($event)" [item]="item" [itemObservation]="itemObservation"
                                              (valueUpdated)="onValueUpdated($event)"></app-observation-document>

                    <!-- ShortText = 10 or ShotTextList = 20 -->
                    <app-observation-short-text *ngIf="isShortText" [form]="form"
                                                (selectedChanged)="onSelectedChanged($event)" [item]="item" [itemObservation]="itemObservation"
                                                (valueUpdated)="onValueUpdated($event)"></app-observation-short-text>

                    <!-- MultiOptionSingle = 11 (ScoreRange = 1, YesPartialNo = 2, LowMediumHigh = 4, NoPartialYes = 6, NoYes = 7) -->
                    <app-observation-multi-option-single *ngIf="isMultiOptionSingle" [form]="form"
                                                         (selectedChanged)="onSelectedChanged($event)" [item]="item" [itemObservation]="itemObservation"
                                                         (valueUpdated)="onValueUpdated($event)"></app-observation-multi-option-single>

                    <!-- Separator = 13 -->
                    <app-observation-separator *ngIf="item.type | isItemType:ItemType.Separator" [item]="item" [itemObservation]="itemObservation">
                    </app-observation-separator>

                    <!-- ESign Document = 14 -->
                    <app-observation-esign-document *ngIf="item.type | isItemType:ItemType.ESignDocument"
                                                    (selectedChanged)="onSelectedChanged($event)" [item]="item" [itemObservation]="itemObservation"
                                                    (valueUpdated)="onValueUpdated($event)"></app-observation-esign-document>

                    <!-- ESign Rich Text = 15 -->
                    <app-observation-esign-document *ngIf="item.type | isItemType:ItemType.ESignRichText"
                                                    (selectedChanged)="onSelectedChanged($event)" [item]="item" [itemObservation]="itemObservation"
                                                    (valueUpdated)="onValueUpdated($event)"></app-observation-esign-document>

                    <!-- Advice Text = 16 -->
                    <app-observation-advice-text *ngIf="item.type | isItemType:ItemType.AdviceText" [item]="item" [itemObservation]="itemObservation">
                    </app-observation-advice-text>

                    <!-- Image = 18 -->
                    <app-observation-image *ngIf="item.type | isItemType:ItemType.Image" [form]="form" (selectedChanged)="onSelectedChanged($event)"
                                           [item]="item" [itemObservation]="itemObservation" (valueUpdated)="onValueUpdated($event)" (valueDeleted)="onValueDeleted($event)">
                    </app-observation-image>

                    <!-- Pass Fail Under Review = 21 -->
                    <app-pass-review-fail-observation *ngIf="item.type | isItemType:ItemType.PassFailUnderReview" [form]="form"
                                                      (selectedChanged)="onSelectedChanged($event)" [item]="item" [itemObservation]="itemObservation"
                                                      (valueUpdated)="onValueUpdated($event)"></app-pass-review-fail-observation>

                    <!-- Credas right to work check = 23 -->
                    <app-observation-right-to-work *ngIf="item.type | isItemType:ItemType.CredasRightToWorkCheck"
                                                   (selectedChanged)="onSelectedChanged($event)" [item]="item" [itemObservation]="itemObservation"></app-observation-right-to-work>

                    <!-- Number = 25 -->
                    <app-observation-number *ngIf="item.type | isItemType:ItemType.Number"
                                            (valueUpdated)="onValueUpdated($event)" [form]="form" [item]="item" [itemObservation]="itemObservation"></app-observation-number>

                    <!-- Selfie = 26 -->
                    <app-observation-selfie *ngIf="item.type | isItemType:ItemType.Selfie"
                                            (valueUpdated)="onValueUpdated($event)" [form]="form" [item]="item" [itemObservation]="itemObservation"
                                            (valueDeleted)="onValueDeleted($event)"></app-observation-selfie>

                    <!-- UAP = 27 -->
                    <app-observation-uap *ngIf="item.type | isItemType:ItemType.UniqueActionProcess"
                                         (valueUpdated)="onValueUpdated($event)" [form]="form" [item]="item" [itemObservation]="itemObservation"
                                         (valueDeleted)="onValueDeleted($event)"></app-observation-uap>

                    <!-- ID Documents = 28 -->
                    <app-observation-id-documents *ngIf="item.type | isItemType:ItemType.IdDocuments"
                                                  (valueUpdated)="onValueUpdated($event)" [form]="form" [item]="item" [itemObservation]="itemObservation"></app-observation-id-documents>

                    <!-- Rich Text = 29 -->
                    <app-observation-rich-text *ngIf="item.type | isItemType:ItemType.RichText"
                                               (valueUpdated)="onValueUpdated($event)" [form]="form" [item]="item" [itemObservation]="itemObservation"></app-observation-rich-text>

                    <!-- Course Selector = 30 -->
                    <app-observation-course-selector *ngIf="item.type | isItemType:ItemType.CourseSelector"
                                                     (valueUpdated)="onValueUpdated($event)" [form]="form" [item]="item" [itemObservation]="itemObservation"></app-observation-course-selector>

                    <!-- Identity Verification = 31 -->
                    <app-observation-identity-verification *ngIf="item.type | isItemType:ItemType.IdentityVerification"
                                                           (valueUpdated)="onValueUpdated($event)" [form]="form" [item]="item" [itemObservation]="itemObservation"></app-observation-identity-verification>

                </div>
                <div *ngIf="isDeletable" fxLayout.xs="column">
                    <button class="btn large primary dark delete-button" (click)="deleteObservation()">
                        <mat-icon>delete</mat-icon> Remove
                    </button>
                </div>

            </div>
            <div *ngIf="item.readonly">
                <div class="readonly">
                    <label *ngIf="item.type !== ItemType.RichText">{{itemObservation.value}}</label>
                    <div *ngIf="item.type === ItemType.RichText" [innerHTML]="itemObservation.value | safe: 'html'"></div>
                </div>
            </div>
        </div>
    </div>
</div>
