import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// ngrx | rxjs
import { ActionsSubject, select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

// store
import * as fromConnect from 'app/connect/store';

// services
import { ModuleService } from 'app/shared/services/module.service';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { CustomMenu } from 'app/models/custom-menu.model';
import { Filter } from 'app/models/filter/filter.model';
import { FilterConfiguration } from 'app/models/filter/filter-configuration.model';
import { FolderSummary } from 'app/connect/models/folder-summary.model';

// enums
import { ViewType } from 'app/connect/enums/view-type.enum';
import { Modules } from 'app/shared/enums';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent extends BaseComponent implements OnInit {
    @Output()
    newClicked: EventEmitter<void> = new EventEmitter();

    @Output()
    search: EventEmitter<Filter> = new EventEmitter();

    @Output()
    export: EventEmitter<Filter> = new EventEmitter();

    @Output()
    customMenuClicked: EventEmitter<CustomMenu> = new EventEmitter();

    @Output()
    openFolderClicked = new EventEmitter<FolderSummary>();

    @Output()
    deleteFolderClicked = new EventEmitter<FolderSummary>();

    @Output()
    editFolderClicked = new EventEmitter<FolderSummary>();

    @Input()
    showAdditionalOptions: boolean = false;

    @Input()
    hasCustomBannerContent: boolean = false;

    @Input()
    layoutSpacing: string = '16px';

    @Input()
    filterConfiguration: FilterConfiguration;

    @Input()
    hideScroll = false;

    @Input()
    showFolders = false;

    @Input()
    backRouterLink: any = undefined;

    visibleRecordCount: number | undefined;

    constructor(
        protected connectStore: Store<fromConnect.ConnectStoreState>,
        protected actionsSubject: ActionsSubject) {
            super();
    }

    ngOnInit(): void {
        this.actionsSubject.pipe(
            ofType(fromConnect.NewButtonClicked),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.newClicked.emit();
            });

        this.actionsSubject.pipe(
            ofType(fromConnect.SearchClicked),
            takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.search.emit(action.filter);
            });

        this.actionsSubject.pipe(
            ofType(fromConnect.ExportWithFilter),
            takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.export.emit(action.filter);
            });

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getFilterConfiguration))
            .subscribe((filterConfiguration: FilterConfiguration) => {
                this.filterConfiguration = filterConfiguration;
            });

        this.actionsSubject.pipe(
            ofType(fromConnect.CustomMenuClicked),
            takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.customMenuClicked.emit(action.menu);
            });

        this.connectStore.dispatch(fromConnect.ResetPage());
    }

    setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
        this.connectStore.dispatch(fromConnect.SetBreadcrumbs({ breadcrumbs }));
    }

    setTitle(title: string): void {
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetPageTitle({ title })));
    }

    setTitleFromModule(moduleId: Modules): void {
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetPageTitle({ title: ModuleService.getTitle(moduleId) })));
    }

    setAllowSwitchView(): void {
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetAllowSwitchView({ allowSwitchView: true })));
    }

    setDefaultView(viewType: ViewType): void {
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetPageViewType({ viewType })));
    }

    setFilterConfiguration(filterConfiguration: FilterConfiguration): void {
        let newFilterConfiguration: FilterConfiguration;
        if (filterConfiguration) {
            newFilterConfiguration = new FilterConfiguration(filterConfiguration.searchLabel, filterConfiguration.isDialog);
            newFilterConfiguration.options = filterConfiguration.options;
            newFilterConfiguration.dateOptions = filterConfiguration.dateOptions;
            newFilterConfiguration.folders = filterConfiguration.folders;
        }
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetPageFilterConfiguration({ filterConfiguration: newFilterConfiguration })));
    }

    setShowMoreButton(): void {
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetShowShowMoreButton({ showShowMoreButton: true })));
    }

    setShowNewButton(showNewButton: boolean, caption: string = 'Add new'): void {
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetShowNewButton({ showNewButton, caption })));
    }

    setAllowExport(): void {
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetAllowExport({ allowExport: true })));
    }

    setHideNavigation(): void {
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetHideNavigation({ hideNavigation: true })));
    }

    setCustomMenus(menus: CustomMenu[]): void {
        setTimeout(() => this.connectStore.dispatch(fromConnect.SetCustomMenus({ menus })));
    }

    setVisibleRecordCount(visibleRecordCount: number): void {
        this.visibleRecordCount = visibleRecordCount;
    }

    clearVisibleRecordCount(): void {
        this.visibleRecordCount = undefined;
    }

    onOpenFolderClicked(folder: FolderSummary): void {
        this.openFolderClicked.emit(folder);
    }

    onDeleteFolderClicked(folder: FolderSummary): void {
        this.deleteFolderClicked.emit(folder);
    }

    onEditFolderClicked(folder: FolderSummary): void {
        this.editFolderClicked.emit(folder);
    }
}