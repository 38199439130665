<div class="photo-capture-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign.xs="start center">

    <div class="options" *ngIf="isDesktop && !disableUpload" fxLayout="column" fxFlex="200px" fxFlex.xs="100px">
        <button class="btn large primary dark" *ngIf="stage === stages.Intro"  (click)="upload()" aria-label="Upload">
            Upload
        </button>
        <button class="btn large primary dark" *ngIf="stage === stages.Capture"  (click)="back()" aria-label="Back">
            Back
        </button>
        <button class="btn large primary dark" *ngIf="allowSwitchToMobile && stage === stages.Intro"   [disabled]="!canContinue" (click)="switchToMobileClicked()" aria-label="Switch to Mobile">
            Use Mobile
        </button>
        <button class="btn large primary dark" *ngIf="webcamAccess && stage === stages.Intro"   [disabled]="!canContinue" (click)="submit()" aria-label="Use Webcam">
            Use Webcam
        </button>
        <button class="btn large primary dark" *ngIf="webcamAccess && stage === stages.Capture"  [disabled]="!canContinue" (click)="submit()" aria-label="Take a Snap">
            Take a Snap 
        </button>
        <button class="btn large primary dark" *ngIf="webcamAccess && cameraStarted && multipleDevices"   (click)="switchWebcam()" aria-label="Switch Camera">
            Switch Camera
        </button>
    </div>
    <div class="options" *ngIf="!isDesktop && !disableUpload">
        <button class="btn large primary dark"  (click)="upload()" aria-label="Upload">
            Take Photo
        </button>
    </div>
    <div class="error">
        <div *ngIf="invalidFileExtension">Invalid file type</div>
        <div *ngIf="invalidFileSize && !invalidFileExtension">Invalid file size, must be smaller than {{maximumFileSizeMb}}MB</div>
        <div *ngIf="processingError">An error occurred during image processing [{{processingErrorCode}}], please try again.</div>
    </div>
    <div class="capture">
        <div class="camera-container" [class.hide]="!isDesktop || !cameraStarted">
            <video #videoDisplay autoplay class="camera">
            </video>
        </div>
        <input #imageUpload class="hide" [accept]="imageFileTypes" [attr.capture]="captureType" type="file" (change)="uploadImage($event)" />
    </div>

    <div class="confirm" [class.hide]="alwaysHidePreview">
        <div fxLayout="column">
            <div *ngIf="showImagePreview" >
                <app-image-card 
                    (imageDownloadClicked)=downloadImageClicked()
                    (imageRemoveClicked)=imageRemovedClicked()
                    [canDownload]="downloadEnabled"
                    [canRemove]="removeEnabled"
                    [src]="imageSrc">
                </app-image-card>
            </div>
            <div [class.hide]="!isDebug || isCanvasEmpty" fxLayout="row" class="preview-canvas-container">
                <canvas #canvasElement class="preview-canvas"></canvas>
            </div>  
       </div>    
    </div>
</div>