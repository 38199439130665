<mat-card fxLayout="column"
    class="group-platform-card badge-card" 
    [title]="badge.title"
    [ngClass]="{'selected': badge.enabled}">
    
    <mat-card-title fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div class="text" fxFlex>{{badge.title}}</div>
        <mat-checkbox *ngIf="!isLocked" class="small" [checked]="badge.enabled" (change)="onEnabledChanged(enabledCheckbox.checked)" #enabledCheckbox></mat-checkbox>
        <mat-icon *ngIf="isLocked" title="This badge is already selected.">verified_user</mat-icon>
    </mat-card-title>
    <mat-card-content fxFlex fxLayout="column">
        <div fxFlex class="font-size-s padding-16 overflow-scroll">{{badge.description}}</div>
    </mat-card-content>
</mat-card>