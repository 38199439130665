<app-dialog dialogTitle="Create a new document" defaultWidth="1000px" defaultHeight="800px" id="document-wizard-dialog">

    <div body [formGroup]="form" fxFlex="100">
        <mat-horizontal-stepper *ngIf="form && organisations && templates" [linear]="false" [selectedIndex]="stepIndex">
            <mat-step>
                <app-document-wizard-step-1 
                    [form]="form"
                    [templates]="templates"
                    (templateChanged)="handleTemplateChanged($event)">
                </app-document-wizard-step-1>
            </mat-step>
            <mat-step>
                <app-document-wizard-step-2
                    [form]="form"
                    [organisations]="organisations"
                    [signatureEmailsValid]="signatureEmailsValid">
                </app-document-wizard-step-2>
            </mat-step>
            <mat-step>
                <app-document-wizard-step-3
                    [form]="form"
                    [organisations]="organisations"
                    (selectedChanged)="handleSelectedSharedOrganisationChanged($event)">
                </app-document-wizard-step-3>
            </mat-step>
            <mat-step>
                <app-document-wizard-step-4
                    [preview]="preview"
                    [loading]="previewLoading$ | async">
                </app-document-wizard-step-4>
            </mat-step>
            <mat-step>
                <app-document-wizard-step-5></app-document-wizard-step-5>
            </mat-step>
        </mat-horizontal-stepper>

        <app-loading-card *ngIf="!form || !organisations || !templates"></app-loading-card>
    </div>

    <div footer-actions>
        <button class="btn large primary light" [disabled]="stepIndex === 4" (click)="close(false)">Close</button>
        <button *ngIf="!finished" class="btn large primary dark" [disabled]="stepIndex === 0 || stepIndex === 4" (click)="previous()">Back</button>
        <button *ngIf="!finished && stepIndex !== 3" class="btn large primary dark" [disabled]="stepIndex === 3 || stepIndex === 4 || !stepValid" (click)="next()">Next</button>
        <button *ngIf="stepIndex === 3" class="btn large primary dark" [disabled]="form.invalid || (previewLoading$ | async)" (click)="save()">Create Document</button>
    </div>

</app-dialog>