<div id="take-photo-dialog" fxLayout="column">
    
    <div mat-dialog-content fxFlex fxLayout="column">
        <div *ngIf="!cameraStarted" fxFlex fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <app-loading-card [useSpinner]="true"></app-loading-card>
                <div>Starting camera....</div>
            </div>
        </div>
        
        <div [class.hide]="!cameraStarted">
            <video #videoDisplay autoplay class="camera">
            </video>
        </div>
        <div [class.hide]="true" fxLayout="row" class="preview-canvas-container">
            <canvas #canvasElement class="preview-canvas"></canvas>
        </div>  
    </div>

    <div [class.hide]="!cameraStarted" class="padding-16" mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
        <button class="btn large primary light" mat-dialog-close><strong>Cancel</strong></button>
        <button class="btn large primary dark" (click)="handleTakePhotoClick()" class="camera-button"><mat-icon class="take-photo">photo_camera</mat-icon></button>
        <button class="btn large primary dark" (click)="handleSwitchWebcamClick()"><mat-icon>cameraswitch</mat-icon></button>
    </div>
</div>
